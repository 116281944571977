import axios from "axios";
import router from "@/router";
const baseUrl = process.env.VUE_APP_API_URL;

export const CHANNEL_TOTAL_CHAT_REPORT_REQUEST =
  "CHANNEL_TOTAL_CHAT_REPORT_REQUEST";
export const CHANNEL_TOTAL_CHAT_REPORT_SUCCESS =
  "CHANNEL_TOTAL_CHAT_REPORT_SUCCESS";
export const CHANNEL_TOTAL_CHAT_REPORT_FAIL = "CHANNEL_TOTAL_CHAT_REPORT_FAIL";
export const CHANNEL_OPEN_CHAT_REPORT_REQUEST =
  "CHANNEL_OPEN_CHAT_REPORT_REQUEST";
export const CHANNEL_OPEN_CHAT_REPORT_SUCCESS =
  "CHANNEL_OPEN_CHAT_REPORT_SUCCESS";
export const CHANNEL_OPEN_CHAT_REPORT_FAIL = "CHANNEL_OPEN_CHAT_REPORT_FAIL";
export const CHANNEL_CLOSED_CHAT_REPORT_REQUEST =
  "CHANNEL_CLOSED_CHAT_REPORT_REQUEST";
export const CHANNEL_CLOSED_CHAT_REPORT_SUCCESS =
  "CHANNEL_CLOSED_CHAT_REPORT_SUCCESS";
export const CHANNEL_CLOSED_CHAT_REPORT_FAIL =
  "CHANNEL_CLOSED_CHAT_REPORT_FAIL";
export const CHANNEL_AVERAGE_HANDLING_TIME_REPORT_REQUEST =
  "CHANNEL_AVERAGE_HANDLING_TIME_REPORT_REQUEST";
export const CHANNEL_AVERAGE_HANDLING_TIME_REPORT_SUCCESS =
  "CHANNEL_AVERAGE_HANDLING_TIME_REPORT_SUCCESS";
export const CHANNEL_AVERAGE_HANDLING_TIME_REPORT_FAIL =
  "CHANNEL_AVERAGE_HANDLING_TIME_REPORT_FAIL";
export const CHANNEL_AVERAGE_QUEUE_TIME_REPORT_REQUEST =
  "CHANNEL_AVERAGE_QUEUE_TIME_REPORT_REQUEST";
export const CHANNEL_AVERAGE_QUEUE_TIME_REPORT_SUCCESS =
  "CHANNEL_AVERAGE_QUEUE_TIME_REPORT_SUCCESS";
export const CHANNEL_AVERAGE_QUEUE_TIME_REPORT_FAIL =
  "CHANNEL_AVERAGE_QUEUE_TIME_REPORT_FAIL";
export const QUEUE_TOTAL_CHAT_REPORT_REQUEST =
  "QUEUE_TOTAL_CHAT_REPORT_REQUEST";
export const QUEUE_TOTAL_CHAT_REPORT_SUCCESS =
  "QUEUE_TOTAL_CHAT_REPORT_SUCCESS";
export const QUEUE_TOTAL_CHAT_REPORT_FAIL = "QUEUE_TOTAL_CHAT_REPORT_FAIL";
export const QUEUE_OPEN_CHAT_REPORT_REQUEST = "QUEUE_OPEN_CHAT_REPORT_REQUEST";
export const QUEUE_OPEN_CHAT_REPORT_SUCCESS = "QUEUE_OPEN_CHAT_REPORT_SUCCESS";
export const QUEUE_OPEN_CHAT_REPORT_FAIL = "QUEUE_OPEN_CHAT_REPORT_FAIL";
export const QUEUE_CLOSED_CHAT_REPORT_REQUEST =
  "QUEUE_CLOSED_CHAT_REPORT_REQUEST";
export const QUEUE_CLOSED_CHAT_REPORT_SUCCESS =
  "QUEUE_CLOSED_CHAT_REPORT_SUCCESS";
export const QUEUE_CLOSED_CHAT_REPORT_FAIL = "QUEUE_CLOSED_CHAT_REPORT_FAIL";
export const QUEUE_AVERAGE_HANDLING_TIME_REPORT_REQUEST =
  "CQUEUE_AVERAGE_HANDLING_TIME_REPORT_REQUEST";
export const QUEUE_AVERAGE_HANDLING_TIME_REPORT_SUCCESS =
  "QUEUE_AVERAGE_HANDLING_TIME_REPORT_SUCCESS";
export const QUEUE_AVERAGE_HANDLING_TIME_REPORT_FAIL =
  "QUEUE_AVERAGE_HANDLING_TIME_REPORT_FAIL";
export const QUEUE_AVERAGE_QUEUE_TIME_REPORT_REQUEST =
  "QUEUE_AVERAGE_QUEUE_TIME_REPORT_REQUEST";
export const QUEUE_AVERAGE_QUEUE_TIME_REPORT_SUCCESS =
  "QUEUE_AVERAGE_QUEUE_TIME_REPORT_SUCCESS";
export const QUEUE_AVERAGE_QUEUE_TIME_REPORT_FAIL =
  "QUEUE_AVERAGE_QUEUE_TIME_REPORT_FAIL";
export const AGENT_TOTAL_CHAT_REPORT_REQUEST =
  "AGENT_TOTAL_CHAT_REPORT_REQUEST";
export const AGENT_TOTAL_CHAT_REPORT_SUCCESS =
  "AGENT_TOTAL_CHAT_REPORT_SUCCESS";
export const AGENT_TOTAL_CHAT_REPORT_FAIL = "AGENT_TOTAL_CHAT_REPORT_FAIL";
export const AGENT_OPEN_CHAT_REPORT_REQUEST = "AGENT_OPEN_CHAT_REPORT_REQUEST";
export const AGENT_OPEN_CHAT_REPORT_SUCCESS = "AGENT_OPEN_CHAT_REPORT_SUCCESS";
export const AGENT_OPEN_CHAT_REPORT_FAIL = "AGENT_OPEN_CHAT_REPORT_FAIL";
export const AGENT_CLOSED_CHAT_REPORT_REQUEST =
  "AGENT_CLOSED_CHAT_REPORT_REQUEST";
export const AGENT_CLOSED_CHAT_REPORT_SUCCESS =
  "AGENT_CLOSED_CHAT_REPORT_SUCCESS";
export const AGENT_CLOSED_CHAT_REPORT_FAIL = "AGENT_CLOSED_CHAT_REPORT_FAIL";
export const AGENT_AVERAGE_HANDLING_TIME_REPORT_REQUEST =
  "AGENT_AVERAGE_HANDLING_TIME_REPORT_REQUEST";
export const AGENT_AVERAGE_HANDLING_TIME_REPORT_SUCCESS =
  "AGENT_AVERAGE_HANDLING_TIME_REPORT_SUCCESS";
export const AGENT_AVERAGE_HANDLING_TIME_REPORT_FAIL =
  "AGENT_AVERAGE_HANDLING_TIME_REPORT_FAIL";
export const AGENT_AVERAGE_QUEUE_TIME_REPORT_REQUEST =
  "AGENT_AVERAGE_QUEUE_TIME_REPORT_REQUEST";
export const AGENT_AVERAGE_QUEUE_TIME_REPORT_SUCCESS =
  "AGENT_AVERAGE_QUEUE_TIME_REPORT_SUCCESS";
export const AGENT_AVERAGE_QUEUE_TIME_REPORT_FAIL =
  "AGENT_AVERAGE_QUEUE_TIME_REPORT_FAIL";

export const GET_CHAT_BOT_FORMS_ITEMS_REQUEST =
  "GET_CHAT_BOT_FORMS_ITEMS_REQUEST";
export const GET_CHAT_BOT_FORMS_ITEMS_SUCCESS =
  "GET_CHAT_BOT_FORMS_ITEMS_SUCCESS";
export const GET_CHAT_BOT_FORMS_ITEMS_FAIL = "GET_CHAT_BOT_FORMS_ITEMS_FAIL";

export const QUEUE_CHAT_REPORT_REQUEST = "QUEUE_CHAT_REPORT_REQUEST";
export const QUEUE_CHAT_REPORT_SUCCESS = "QUEUE_CHAT_REPORT_SUCCESS";
export const QUEUE_CHAT_REPORT_FAIL = "QUEUE_CHAT_REPORT_FAIL";
export const AGENT_CHAT_REPORT_REQUEST = "AGENT_CHAT_REPORT_REQUEST";
export const AGENT_CHAT_REPORT_SUCCESS = "AGENT_CHAT_REPORT_SUCCESS";
export const AGENT_CHAT_REPORT_FAIL = "AGENT_CHAT_REPORT_FAIL";
export const QUEUE_ANSWERED_REPORT_REQUEST = "QUEUE_ANSWERED_REPORT_REQUEST";
export const QUEUE_ANSWERED_REPORT_SUCCESS = "QUEUE_ANSWERED_REPORT_SUCCESS";
export const QUEUE_ANSWERED_REPORT_FAIL = "QUEUE_ANSWERED_REPORT_FAIL";
export const QUEUE_ABANDONED_REPORT_REQUEST = "QUEUE_ABANDONED_REPORT_REQUEST";
export const QUEUE_ABANDONED_REPORT_SUCCESS = "QUEUE_ABANDONED_REPORT_SUCCESS";
export const QUEUE_ABANDONED_REPORT_FAIL = "QUEUE_ABANDONED_REPORT_FAIL";
export const QUEUE_TOTALCALL_REPORT_REQUEST = "QUEUE_TOTALCALL_REPORT_REQUEST";
export const QUEUE_TOTALCALL_REPORT_SUCCESS = "QUEUE_TOTALCALL_REPORT_SUCCESS";
export const QUEUE_TOTALCALL_REPORT_FAIL = "QUEUE_TOTALCALL_REPORT_FAIL";
export const QUEUE_AHT_REPORT_REQUEST = "QUEUE_AHT_REPORT_REQUEST";
export const QUEUE_AHT_REPORT_SUCCESS = "QUEUE_AHT_REPORT_SUCCESS";
export const QUEUE_AHT_REPORT_FAIL = "QUEUE_AHT_REPORT_FAIL";
export const QUEUE_CALLTIME_REPORT_REQUEST = "QUEUE_CALLTIME_REPORT_REQUEST";
export const QUEUE_CALLTIME_REPORT_SUCCESS = "QUEUE_CALLTIME_REPORT_SUCCESS";
export const QUEUE_CALLTIME_REPORT_FAIL = "QUEUE_CALLTIME_REPORT_FAIL";
export const QUEUE_HOLDTIME_REPORT_REQUEST = "QUEUE_HOLDTIME_REPORT_REQUEST";
export const QUEUE_HOLDTIME_REPORT_SUCCESS = "QUEUE_HOLDTIME_REPORT_SUCCESS";
export const QUEUE_HOLDTIME_REPORT_FAIL = "QUEUE_HOLDTIME_REPORT_FAIL";
export const QUEUE_MUTETIME_REPORT_REQUEST = "QUEUE_MUTETIME_REPORT_REQUEST";
export const QUEUE_MUTETIME_REPORT_SUCCESS = "QUEUE_MUTETIME_REPORT_SUCCESS";
export const QUEUE_MUTETIME_REPORT_FAIL = "QUEUE_MUTETIME_REPORT_FAIL";
export const QUEUE_SERVICELEVEL_REPORT_REQUEST =
  "QUEUE_SERVICELEVEL_REPORT_REQUEST";
export const QUEUE_SERVICELEVEL_REPORT_SUCCESS =
  "QUEUE_SERVICELEVEL_REPORT_SUCCESS";
export const QUEUE_SERVICELEVEL_REPORT_FAIL = "QUEUE_SERVICELEVEL_REPORT_FAIL";
export const QUEUE_FIRSTCALL_REPORT_REQUEST = "QUEUE_FIRSTCALL_REPORT_REQUEST";
export const QUEUE_FIRSTCALL_REPORT_SUCCESS = "QUEUE_FIRSTCALL_REPORT_SUCCESS";
export const QUEUE_FIRSTCALL_REPORT_FAIL = "QUEUE_FIRSTCALL_REPORT_FAIL";

export const AGENT_ANSWERED_REPORT_REQUEST = "AGENT_ANSWERED_REPORT_REQUEST";
export const AGENT_ANSWERED_REPORT_SUCCESS = "AGENT_ANSWERED_REPORT_SUCCESS";
export const AGENT_ANSWERED_REPORT_FAIL = "AGENT_ANSWERED_REPORT_FAIL";
export const AGENT_ABANDONED_REPORT_REQUEST = "AGENT_ABANDONED_REPORT_REQUEST";
export const AGENT_ABANDONED_REPORT_SUCCESS = "AGENT_ABANDONED_REPORT_SUCCESS";
export const AGENT_ABANDONED_REPORT_FAIL = "AGENT_ABANDONED_REPORT_FAIL";
export const AGENT_TOTALCALL_REPORT_REQUEST = "AGENT_TOTALCALL_REPORT_REQUEST";
export const AGENT_TOTALCALL_REPORT_SUCCESS = "AGENT_TOTALCALL_REPORT_SUCCESS";
export const AGENT_TOTALCALL_REPORT_FAIL = "AGENT_TOTALCALL_REPORT_FAIL";
export const AGENT_AHT_REPORT_REQUEST = "AGENT_AHT_REPORT_REQUEST";
export const AGENT_AHT_REPORT_SUCCESS = "AGENT_AHT_REPORT_SUCCESS";
export const AGENT_AHT_REPORT_FAIL = "AGENT_AHT_REPORT_FAIL";
export const AGENT_CALLTIME_REPORT_REQUEST = "AGENT_CALLTIME_REPORT_REQUEST";
export const AGENT_CALLTIME_REPORT_SUCCESS = "AGENT_CALLTIME_REPORT_SUCCESS";
export const AGENT_CALLTIME_REPORT_FAIL = "AGENT_CALLTIME_REPORT_FAIL";
export const AGENT_HOLDTIME_REPORT_REQUEST = "AGENT_HOLDTIME_REPORT_REQUEST";
export const AGENT_HOLDTIME_REPORT_SUCCESS = "AGENT_HOLDTIME_REPORT_SUCCESS";
export const AGENT_HOLDTIME_REPORT_FAIL = "AGENT_HOLDTIME_REPORT_FAIL";
export const AGENT_MUTETIME_REPORT_REQUEST = "AGENT_MUTETIME_REPORT_REQUEST";
export const AGENT_MUTETIME_REPORT_SUCCESS = "AGENT_MUTETIME_REPORT_SUCCESS";
export const AGENT_MUTETIME_REPORT_FAIL = "AGENT_MUTETIME_REPORT_FAIL";
export const AGENT_TIMETOANSWER_REPORT_REQUEST =
  "AGENT_TIMETOANSWER_REPORT_REQUEST";
export const AGENT_TIMETOANSWER_REPORT_SUCCESS =
  "AGENT_TIMETOANSWER_REPORT_SUCCESS";
export const AGENT_TIMETOANSWER_REPORT_FAIL = "AGENT_TIMETOANSWER_REPORT_FAIL";

export const AGENT_CLICK_ANSWERED_REPORT_REQUEST =
  "AGENT_CLICK_ANSWERED_REPORT_REQUEST";
export const AGENT_CLICK_ANSWERED_REPORT_SUCCESS =
  "AGENT_CLICK_ANSWERED_REPORT_SUCCESS";
export const AGENT_CLICK_ANSWERED_REPORT_FAIL =
  "AGENT_CLICK_ANSWERED_REPORT_FAIL";
export const AGENT_CLICK_ABANDONED_REPORT_REQUEST =
  "AGENT_CLICK_ABANDONED_REPORT_REQUEST";
export const AGENT_CLICK_ABANDONED_REPORT_SUCCESS =
  "AGENT_CLICK_ABANDONED_REPORT_SUCCESS";
export const AGENT_CLICK_ABANDONED_REPORT_FAIL =
  "AGENT_CLICK_ABANDONED_REPORT_FAIL";
export const AGENT_CLICK_TOTALCALL_REPORT_REQUEST =
  "AGENT_CLICK_TOTALCALL_REPORT_REQUEST";
export const AGENT_CLICK_TOTALCALL_REPORT_SUCCESS =
  "AGENT_CLICK_TOTALCALL_REPORT_SUCCESS";
export const AGENT_CLICK_TOTALCALL_REPORT_FAIL =
  "AGENT_CLICK_TOTALCALL_REPORT_FAIL";
export const AGENT_CLICK_AHT_REPORT_REQUEST = "AGENT_CLICK_AHT_REPORT_REQUEST";
export const AGENT_CLICK_AHT_REPORT_SUCCESS = "AGENT_CLICK_AHT_REPORT_SUCCESS";
export const AGENT_CLICK_AHT_REPORT_FAIL = "AGENT_CLICK_AHT_REPORT_FAIL";
export const AGENT_CLICK_CALLTIME_REPORT_REQUEST =
  "AGENT_CLICK_CALLTIME_REPORT_REQUEST";
export const AGENT_CLICK_CALLTIME_REPORT_SUCCESS =
  "AGENT_CLICK_CALLTIME_REPORT_SUCCESS";
export const AGENT_CLICK_CALLTIME_REPORT_FAIL =
  "AGENT_CLICK_CALLTIME_REPORT_FAIL";
export const AGENT_CLICK_HOLDTIME_REPORT_REQUEST =
  "AGENT_CLICK_HOLDTIME_REPORT_REQUEST";
export const AGENT_CLICK_HOLDTIME_REPORT_SUCCESS =
  "AGENT_CLICK_HOLDTIME_REPORT_SUCCESS";
export const AGENT_CLICK_HOLDTIME_REPORT_FAIL =
  "AGENT_CLICK_HOLDTIME_REPORT_FAIL";
export const AGENT_CLICK_MUTETIME_REPORT_REQUEST =
  "AGENT_CLICK_MUTETIME_REPORT_REQUEST";
export const AGENT_CLICK_MUTETIME_REPORT_SUCCESS =
  "AGENT_CLICK_MUTETIME_REPORT_SUCCESS";
export const AGENT_CLICK_MUTETIME_REPORT_FAIL =
  "AGENT_CLICK_MUTETIME_REPORT_FAIL";
export const AGENT_CLICK_TIMETOANSWER_REPORT_REQUEST =
  "AGENT_CLICK_TIMETOANSWER_REPORT_REQUEST";
export const AGENT_CLICK_TIMETOANSWER_REPORT_SUCCESS =
  "AGENT_CLICK_TIMETOANSWER_REPORT_SUCCESS";
export const AGENT_CLICK_TIMETOANSWER_REPORT_FAIL =
  "AGENT_CLICK_TIMETOANSWER_REPORT_FAIL";

export const AGENT_CALL_REPORT_REQUEST = "AGENT_CALL_REPORT_REQUEST";
export const AGENT_CALL_REPORT_SUCCESS = "AGENT_CALL_REPORT_SUCCESS";
export const AGENT_CALL_REPORT_FAIL = "AGENT_CALL_REPORT_FAIL";
export const CDR_REPORT_REQUEST = "CDR_REPORT_REQUEST";
export const CDR_REPORT_SUCCESS = "CDR_REPORT_SUCCESS";
export const CDR_REPORT_FAIL = "CDR_REPORT_FAIL";
export const CDR_REPORT_EXPORT_REQUEST = "CDR_REPORT_EXPORT_REQUEST";
export const CDR_REPORT_EXPORT_SUCCESS = "CDR_REPORT_EXPORT_SUCCESS";
export const CDR_REPORT_EXPORT_FAIL = "CDR_REPORT_EXPORT_FAIL";
export const AGENT_ACTIVITY_REPORT_REQUEST = "AGENT_ACTIVITY_REPORT_REQUEST";
export const AGENT_ACTIVITY_REPORT_SUCCESS = "AGENT_ACTIVITY_REPORT_SUCCESS";
export const AGENT_ACTIVITY_REPORT_FAIL = "AGENT_ACTIVITY_REPORT_FAIL";

export const AGENT_ACTIVITY_REPORT_RAW_REQUEST =
  "AGENT_ACTIVITY_REPORT_RAW_REQUEST";
export const AGENT_ACTIVITY_REPORT_RAW_SUCCESS =
  "AGENT_ACTIVITY_REPORT_RAW_SUCCESS";
export const AGENT_ACTIVITY_REPORT_RAW_FAIL = "AGENT_ACTIVITY_REPORT_RAW_FAIL";

export const IVR_HIT_REPORT_REQUEST = "IVR_HIT_REPORT_REQUEST";
export const IVR_HIT_REPORT_SUCCESS = "IVR_HIT_REPORT_SUCCESS";
export const IVR_HIT_REPORT_FAIL = "IVR_HIT_REPORT_FAIL";
export const IVR_BACKGROUND_REQUEST = "IVR_BACKGROUND_REQUEST";
export const IVR_BACKGROUND_SUCCESS = "IVR_BACKGROUND_SUCCESS";
export const IVR_BACKGROUND_FAIL = "IVR_BACKGROUND_FAIL";

export const GET_CHATBOT_REPORT_CUMULATIVE_REQUEST =
  "GET_CHATBOT_REPORT_CUMULATIVE_REQUEST";
export const GET_CHATBOT_REPORT_CUMULATIVE_SUCCESS =
  "GET_CHATBOT_REPORT_CUMULATIVE_SUCCESS";
export const GET_CHATBOT_REPORT_CUMULATIVE_FAIL =
  "GET_CHATBOT_REPORT_CUMULATIVE_FAIL";

const state = {
  loadingReports: false,
  successReports: "",
  errorReports: "",
  channelTotalChatLabel: [],
  channelTotalChatData: [],
  channelOpenChatLabel: [],
  channelOpenChatData: [],
  channelClosedChatLabel: [],
  channelClosedChatData: [],
  channelAverageHandlingTimeLabel: [],
  channelAverageHandlingTimeData: [],
  channelAverageQueueTimeLabel: [],
  channelAverageQueueTimeData: [],
  queueTotalChatData: [],
  queueOpenChatData: [],
  queueClosedChatData: [],
  queueAverageHandlingTimeData: [],
  queueAverageQueueTimeData: [],
  agentTotalChatData: [],
  agentOpenChatData: [],
  agentClosedChatData: [],
  agentAverageHandlingTimeData: [],
  agentAverageQueueTimeData: [],
  chatBotOptionsOnly: [],
  chatBotOptionsItems: [],
  queueAnsweredLabel: [],
  queueAnsweredData: [],
  queueAbandonedLabel: [],
  queueAbandonedData: [],
  queueTotalCallLabel: [],
  queueTotalCallData: [],
  queueAHTLabel: [],
  queueAHTData: [],
  queueCallTimeLabel: [],
  queueCallTimeData: [],
  queueHoldTimeLabel: [],
  queueHoldTimeData: [],
  queueMuteTimeLabel: [],
  queueMuteTimeData: [],
  queueServiceLevelLabel: [],
  queueServiceLevelData: [],
  queueFirstCallLabel: [],
  queueFirstCallData: [],

  agentAnsweredLabel: [],
  agentAnsweredData: [],
  agentAbandonedLabel: [],
  agentAbandonedData: [],
  agentTotalCallLabel: [],
  agentTotalCallData: [],
  agentAHTLabel: [],
  agentAHTData: [],
  agentCallTimeLabel: [],
  agentCallTimeData: [],
  agentHoldTimeLabel: [],
  agentHoldTimeData: [],
  agentMuteTimeLabel: [],
  agentMuteTimeData: [],
  agentTimeToAnswerLabel: [],
  agentTimeToAnswerData: [],

  agentClickAnsweredLabel: [],
  agentClickAnsweredData: [],
  agentClickAbandonedLabel: [],
  agentClickAbandonedData: [],
  agentClickTotalCallLabel: [],
  agentClickTotalCallData: [],
  agentClickAHTLabel: [],
  agentClickAHTData: [],
  agentClickCallTimeLabel: [],
  agentClickCallTimeData: [],
  agentClickHoldTimeLabel: [],
  agentClickHoldTimeData: [],
  agentClickMuteTimeLabel: [],
  agentClickMuteTimeData: [],
  agentClickTimeToAnswerLabel: [],
  agentClickTimeToAnswerData: [],

  agentCallcumulativeLabel: [],
  agentCallcumulativeData: [],
  agentCallPerQueue: [],
  cdrReport: [],
  cdrReportExport: [],
  agentActivityReport: [],
  ivrHitLabel: [],
  ivrHitData: [],
  ivrBackground: [],
  selectIvrBackground: [],
  pageNumbers: "",
  agentActivityReportRawSession: [],
  agentActivityReportRawBreaks: [],

  chatBotReportCumulative: [],
};

const getters = {
  loadingReports: (state) => state.loadingReports,
  successReports: (state) => state.successReports,
  errorReports: (state) => state.errorReports,

  channelTotalChatLabel: (state) => state.channelTotalChatLabel,
  channelTotalChatData: (state) => state.channelTotalChatData,
  channelOpenChatLabel: (state) => state.channelOpenChatLabel,
  channelOpenChatData: (state) => state.channelOpenChatData,
  channelClosedChatLabel: (state) => state.channelClosedChatLabel,
  channelClosedChatData: (state) => state.channelClosedChatData,
  channelAverageHandlingTimeLabel: (state) =>
    state.channelAverageHandlingTimeLabel,
  channelAverageHandlingTimeData: (state) =>
    state.channelAverageHandlingTimeData,
  channelAverageQueueTimeLabel: (state) => state.channelAverageQueueTimeLabel,
  channelAverageQueueTimeData: (state) => state.channelAverageQueueTimeData,

  queueTotalChatData: (state) => state.queueTotalChatData,
  queueOpenChatData: (state) => state.queueOpenChatData,
  queueClosedChatData: (state) => state.queueClosedChatData,
  queueAverageHandlingTimeData: (state) => state.queueAverageHandlingTimeData,
  queueAverageQueueTimeData: (state) => state.queueAverageQueueTimeData,

  agentTotalChatData: (state) => state.agentTotalChatData,
  agentOpenChatData: (state) => state.agentOpenChatData,
  agentClosedChatData: (state) => state.agentClosedChatData,
  agentAverageHandlingTimeData: (state) => state.agentAverageHandlingTimeData,
  agentAverageQueueTimeData: (state) => state.agentAverageQueueTimeData,

  chatBotOptionsOnly: (state) => state.chatBotOptionsOnly,
  chatBotOptionsItems: (state) => state.chatBotOptionsItems,

  queueAnsweredLabel: (state) => state.queueAnsweredLabel,
  queueAnsweredData: (state) => state.queueAnsweredData,
  queueAbandonedLabel: (state) => state.queueAbandonedLabel,
  queueAbandonedData: (state) => state.queueAbandonedData,
  queueTotalCallLabel: (state) => state.queueTotalCallLabel,
  queueTotalCallData: (state) => state.queueTotalCallData,
  queueAHTLabel: (state) => state.queueAHTLabel,
  queueAHTData: (state) => state.queueAHTData,
  queueCallTimeLabel: (state) => state.queueCallTimeLabel,
  queueCallTimeData: (state) => state.queueCallTimeData,
  queueHoldTimeLabel: (state) => state.queueHoldTimeLabel,
  queueHoldTimeData: (state) => state.queueHoldTimeData,
  queueMuteTimeLabel: (state) => state.queueMuteTimeLabel,
  queueMuteTimeData: (state) => state.queueMuteTimeData,
  queueServiceLevelLabel: (state) => state.queueServiceLevelLabel,
  queueServiceLevelData: (state) => state.queueServiceLevelData,
  queueFirstCallLabel: (state) => state.queueFirstCallLabel,
  queueFirstCallData: (state) => state.queueFirstCallData,
  pageNumbers: (state) => state.pageNumbers,

  agentAnsweredLabel: (state) => state.agentAnsweredLabel,
  agentAnsweredData: (state) => state.agentAnsweredData,
  agentAbandonedLabel: (state) => state.agentAbandonedLabel,
  agentAbandonedData: (state) => state.agentAbandonedData,
  agentTotalCallLabel: (state) => state.agentTotalCallLabel,
  agentTotalCallData: (state) => state.agentTotalCallData,
  agentAHTLabel: (state) => state.agentAHTLabel,
  agentAHTData: (state) => state.agentAHTData,
  agentCallTimeLabel: (state) => state.agentCallTimeLabel,
  agentCallTimeData: (state) => state.agentCallTimeData,
  agentHoldTimeLabel: (state) => state.agentHoldTimeLabel,
  agentHoldTimeData: (state) => state.agentHoldTimeData,
  agentMuteTimeLabel: (state) => state.agentMuteTimeLabel,
  agentMuteTimeData: (state) => state.agentMuteTimeData,
  agentTimeToAnswerLabel: (state) => state.agentTimeToAnswerLabel,
  agentTimeToAnswerData: (state) => state.agentTimeToAnswerData,

  agentClickAnsweredLabel: (state) => state.agentClickAnsweredLabel,
  agentClickAnsweredData: (state) => state.agentClickAnsweredData,
  agentClickAbandonedLabel: (state) => state.agentClickAbandonedLabel,
  agentClickAbandonedData: (state) => state.agentClickAbandonedData,
  agentClickTotalCallLabel: (state) => state.agentClickTotalCallLabel,
  agentClickTotalCallData: (state) => state.agentClickTotalCallData,
  agentClickAHTLabel: (state) => state.agentClickAHTLabel,
  agentClickAHTData: (state) => state.agentClickAHTData,
  agentClickCallTimeLabel: (state) => state.agentClickCallTimeLabel,
  agentClickCallTimeData: (state) => state.agentClickCallTimeData,
  agentClickHoldTimeLabel: (state) => state.agentClickHoldTimeLabel,
  agentClickHoldTimeData: (state) => state.agentClickHoldTimeData,
  agentClickMuteTimeLabel: (state) => state.agentClickMuteTimeLabel,
  agentClickMuteTimeData: (state) => state.agentClickMuteTimeData,
  agentClickTimeToAnswerLabel: (state) => state.agentClickTimeToAnswerLabel,
  agentClickTimeToAnswerData: (state) => state.agentClickTimeToAnswerData,

  agentCallcumulativeLabel: (state) => state.agentCallcumulativeLabel,
  agentCallcumulativeData: (state) => state.agentCallcumulativeData,
  agentCallPerQueue: (state) => state.agentCallPerQueue,
  cdrReport: (state) => state.cdrReport,
  cdrReportExport: (state) => state.cdrReportExport,
  agentActivityReport: (state) => state.agentActivityReport,
  ivrHitLabel: (state) => state.ivrHitLabel,
  ivrHitData: (state) => state.ivrHitData,
  ivrBackground: (state) => state.ivrBackground,
  selectIvrBackground: (state) => state.selectIvrBackground,
  agentActivityReportRawBreaks: (state) => state.agentActivityReportRawBreaks,
  agentActivityReportRawSession: (state) => state.agentActivityReportRawSession,

  chatBotReportCumulative: (state) => state.chatBotReportCumulative,
};

const mutations = {
  /* 
    CHANNEL CHAT REPORT INFO
  */
  [CHANNEL_TOTAL_CHAT_REPORT_REQUEST](state) {
    state.loadingReports = true;
    state.errorReports = "";
  },
  [CHANNEL_TOTAL_CHAT_REPORT_SUCCESS](state, payload) {
    state.loadingReports = false;
    state.channelTotalChatData = payload;
  },
  [CHANNEL_TOTAL_CHAT_REPORT_FAIL](state, errorReports) {
    state.loadingReports = false;
    state.errorReports = errorReports;
  },
  /* 
    CHANNEL OPEN REPORT INFO
  */
  [CHANNEL_OPEN_CHAT_REPORT_REQUEST](state) {
    state.loadingReports = true;
    state.errorReports = "";
  },
  [CHANNEL_OPEN_CHAT_REPORT_SUCCESS](state, payload) {
    state.loadingReports = false;
    state.channelOpenChatData = payload;
  },
  [CHANNEL_OPEN_CHAT_REPORT_FAIL](state, errorReports) {
    state.loadingReports = false;
    state.errorReports = errorReports;
  },
  /* 
    CHANNEL CLOSED REPORT INFO
  */
  [CHANNEL_CLOSED_CHAT_REPORT_REQUEST](state) {
    state.loadingReports = true;
    state.errorReports = "";
  },
  [CHANNEL_CLOSED_CHAT_REPORT_SUCCESS](state, payload) {
    state.loadingReports = false;
    state.channelClosedChatData = payload;
  },
  [CHANNEL_CLOSED_CHAT_REPORT_FAIL](state, errorReports) {
    state.loadingReports = false;
    state.errorReports = errorReports;
  },
  /* 
    CHANNEL AHT REPORT INFO
  */
  [CHANNEL_AVERAGE_HANDLING_TIME_REPORT_REQUEST](state) {
    state.loadingReports = true;
    state.errorReports = "";
  },
  [CHANNEL_AVERAGE_HANDLING_TIME_REPORT_SUCCESS](state, payload) {
    state.loadingReports = false;
    state.channelAverageHandlingTimeData = payload;
  },
  [CHANNEL_AVERAGE_HANDLING_TIME_REPORT_FAIL](state, errorReports) {
    state.loadingReports = false;
    state.errorReports = errorReports;
  },
  /* 
    CHANNEL AQT REPORT INFO
  */
  [CHANNEL_AVERAGE_QUEUE_TIME_REPORT_REQUEST](state) {
    state.loadingReports = true;
    state.errorReports = "";
  },
  [CHANNEL_AVERAGE_QUEUE_TIME_REPORT_SUCCESS](state, payload) {
    state.loadingReports = false;
    state.channelAverageQueueTimeData = payload;
  },
  [CHANNEL_AVERAGE_QUEUE_TIME_REPORT_FAIL](state, errorReports) {
    state.loadingReports = false;
    state.errorReports = errorReports;
  },
  /* 
    QUEUE CHAT REPORT INFO
  */
  [QUEUE_TOTAL_CHAT_REPORT_REQUEST](state) {
    state.loadingReports = true;
    state.errorReports = "";
  },
  [QUEUE_TOTAL_CHAT_REPORT_SUCCESS](state, payload) {
    state.loadingReports = false;
    state.queueTotalChatData = payload;
  },
  [QUEUE_TOTAL_CHAT_REPORT_FAIL](state, errorReports) {
    state.loadingReports = false;
    state.errorReports = errorReports;
  },
  /* 
      QUEUE OPEN REPORT INFO
    */
  [QUEUE_OPEN_CHAT_REPORT_REQUEST](state) {
    state.loadingReports = true;
    state.errorReports = "";
  },
  [QUEUE_OPEN_CHAT_REPORT_SUCCESS](state, payload) {
    state.loadingReports = false;
    state.queueOpenChatData = payload;
  },
  [QUEUE_OPEN_CHAT_REPORT_FAIL](state, errorReports) {
    state.loadingReports = false;
    state.errorReports = errorReports;
  },
  /* 
      QUEUE CLOSED REPORT INFO
    */
  [QUEUE_CLOSED_CHAT_REPORT_REQUEST](state) {
    state.loadingReports = true;
    state.errorReports = "";
  },
  [QUEUE_CLOSED_CHAT_REPORT_SUCCESS](state, payload) {
    state.loadingReports = false;
    state.queueClosedChatData = payload;
  },
  [QUEUE_CLOSED_CHAT_REPORT_FAIL](state, errorReports) {
    state.loadingReports = false;
    state.errorReports = errorReports;
  },
  /* 
      QUEUE AHT REPORT INFO
    */
  [QUEUE_AVERAGE_HANDLING_TIME_REPORT_REQUEST](state) {
    state.loadingReports = true;
    state.errorReports = "";
  },
  [QUEUE_AVERAGE_HANDLING_TIME_REPORT_SUCCESS](state, payload) {
    state.loadingReports = false;
    state.queueAverageHandlingTimeData = payload;
  },
  [QUEUE_AVERAGE_HANDLING_TIME_REPORT_FAIL](state, errorReports) {
    state.loadingReports = false;
    state.errorReports = errorReports;
  },
  /* 
      QUEUE AQT REPORT INFO
    */
  [QUEUE_AVERAGE_QUEUE_TIME_REPORT_REQUEST](state) {
    state.loadingReports = true;
    state.errorReports = "";
  },
  [QUEUE_AVERAGE_QUEUE_TIME_REPORT_SUCCESS](state, payload) {
    state.loadingReports = false;
    state.queueAverageQueueTimeData = payload;
  },
  [QUEUE_AVERAGE_QUEUE_TIME_REPORT_FAIL](state, errorReports) {
    state.loadingReports = false;
    state.errorReports = errorReports;
  },
  /* 
    AGENT TOTAL CHAT REPORT INFO
  */
  [AGENT_TOTAL_CHAT_REPORT_REQUEST](state) {
    state.loadingReports = true;
    state.errorReports = "";
  },
  [AGENT_TOTAL_CHAT_REPORT_SUCCESS](state, payload) {
    state.loadingReports = false;
    state.agentTotalChatData = payload;
  },
  [AGENT_TOTAL_CHAT_REPORT_FAIL](state, errorReports) {
    state.loadingReports = false;
    state.errorReports = errorReports;
  },
  /* 
    AGENT OPEN REPORT INFO
  */
  [AGENT_OPEN_CHAT_REPORT_REQUEST](state) {
    state.loadingReports = true;
    state.errorReports = "";
  },
  [AGENT_OPEN_CHAT_REPORT_SUCCESS](state, payload) {
    state.loadingReports = false;
    state.agentOpenChatData = payload;
  },
  [AGENT_OPEN_CHAT_REPORT_FAIL](state, errorReports) {
    state.loadingReports = false;
    state.errorReports = errorReports;
  },
  /* 
    AGENT CLOSED REPORT INFO
  */
  [AGENT_CLOSED_CHAT_REPORT_REQUEST](state) {
    state.loadingReports = true;
    state.errorReports = "";
  },
  [AGENT_CLOSED_CHAT_REPORT_SUCCESS](state, payload) {
    state.loadingReports = false;
    state.agentClosedChatData = payload;
  },
  [AGENT_CLOSED_CHAT_REPORT_FAIL](state, errorReports) {
    state.loadingReports = false;
    state.errorReports = errorReports;
  },
  /* 
    AGENT AHT REPORT INFO
  */
  [AGENT_AVERAGE_HANDLING_TIME_REPORT_REQUEST](state) {
    state.loadingReports = true;
    state.errorReports = "";
  },
  [AGENT_AVERAGE_HANDLING_TIME_REPORT_SUCCESS](state, payload) {
    state.loadingReports = false;
    state.agentAverageHandlingTimeData = payload;
  },
  [AGENT_AVERAGE_HANDLING_TIME_REPORT_FAIL](state, errorReports) {
    state.loadingReports = false;
    state.errorReports = errorReports;
  },
  /* 
    AGENT AQT REPORT INFO
  */
  [AGENT_AVERAGE_QUEUE_TIME_REPORT_REQUEST](state) {
    state.loadingReports = true;
    state.errorReports = "";
  },
  [AGENT_AVERAGE_QUEUE_TIME_REPORT_SUCCESS](state, payload) {
    state.loadingReports = false;
    state.agentAverageQueueTimeData = payload;
  },
  [AGENT_AVERAGE_QUEUE_TIME_REPORT_FAIL](state, errorReports) {
    state.loadingReports = false;
    state.errorReports = errorReports;
  },

  /* 
    GET CHATBOT FORMS ITEMS
  */
  [GET_CHAT_BOT_FORMS_ITEMS_REQUEST](state) {
    state.loadingReports = true;
    state.errorReports = "";
  },
  [GET_CHAT_BOT_FORMS_ITEMS_SUCCESS](state, payload) {
    localStorage.removeItem("Form Info");
    state.loadingReports = false;
    state.chatBotOptionsItems = payload;
    state.chatBotOptionsOnly = payload.filter(function (item) {
      return (
        item.data_type === "select" ||
        item.data_type === "radio" ||
        item.data_type === "dropdown" ||
        item.data_type === "radiobutton" ||
        item.data_type === "checkbox"
      );
    });
    console.log(
      "these are the only items with options",
      state.chatBotOptionsOnly
    );
    state.chatBotOptionsOnly = state.chatBotOptionsOnly.map((option) => {
      return { label: option.flow_name, value: option.id };
    });
    var formItem = [{ multipleNames: [], form_items: [] }];
    for (var i = 0; i < payload?.length; i++) {
      if (payload[i].data_type == "select") {
        var selections = [];
        for (var s = 0; s < payload[i].CENTRAL_form_attr_options?.length; s++) {
          selections.push({
            option: payload[i].CENTRAL_form_attr_options[s].option_name,
          });
        }
        formItem[0].form_items.push({
          name: payload[i].name,
          placeholder: payload[i].place_holder,
          is_required: payload[i].is_required,
          data_type: payload[i].data_type,
          is_masked: payload[i].is_masked,
          sequence: payload[i].sequence,
          options: selections,
        });
      } else {
        formItem[0].form_items.push({
          name: payload[i].name,
          placeholder: payload[i].place_holder,
          is_required: payload[i].is_required,
          data_type: payload[i].data_type,
          is_masked: payload[i].is_masked,
          sequence: payload[i].sequence,
        });
      }
    }
    console.log("form items constructed", formItem);
    localStorage.setItem("Form Info", JSON.stringify(formItem));
  },
  [GET_CHAT_BOT_FORMS_ITEMS_FAIL](state, errorReports) {
    state.loadingReports = false;
    state.errorReports = errorReports;
  },

  /* 
    QUEUE ANSWERED REPORT INFO
  */
  [QUEUE_ANSWERED_REPORT_REQUEST](state) {
    state.loadingReports = true;
    state.errorReports = "";
  },
  [QUEUE_ANSWERED_REPORT_SUCCESS](state, payload) {
    state.loadingReports = false;

    state.queueAnsweredData = payload;
  },
  [QUEUE_ANSWERED_REPORT_FAIL](state, errorReports) {
    state.loadingReports = false;
    state.errorReports = errorReports;
  },
  /* 
    QUEUE ABANDONED REPORT INFO
  */
  [QUEUE_ABANDONED_REPORT_REQUEST](state) {
    state.loadingReports = true;
    state.errorReports = "";
  },
  [QUEUE_ABANDONED_REPORT_SUCCESS](state, payload) {
    state.loadingReports = false;

    state.queueAbandonedData = payload;
  },
  [QUEUE_ABANDONED_REPORT_FAIL](state, errorReports) {
    state.loadingReports = false;
    state.errorReports = errorReports;
  },
  /* 
    QUEUE TOTALCALL REPORT INFO
  */
  [QUEUE_TOTALCALL_REPORT_REQUEST](state) {
    state.loadingReports = true;
    state.errorReports = "";
  },
  [QUEUE_TOTALCALL_REPORT_SUCCESS](state, payload) {
    state.loadingReports = false;

    state.queueTotalCallData = payload;
  },
  [QUEUE_TOTALCALL_REPORT_FAIL](state, errorReports) {
    state.loadingReports = false;
    state.errorReports = errorReports;
  },
  /* 
    QUEUE AHT REPORT INFO
  */
  [QUEUE_AHT_REPORT_REQUEST](state) {
    state.loadingReports = true;
    state.errorReports = "";
  },
  [QUEUE_AHT_REPORT_SUCCESS](state, payload) {
    state.loadingReports = false;

    state.queueAHTData = payload;
  },
  [QUEUE_AHT_REPORT_FAIL](state, errorReports) {
    state.loadingReports = false;
    state.errorReports = errorReports;
  },
  /* 
    QUEUE CALLTIME REPORT INFO
  */
  [QUEUE_CALLTIME_REPORT_REQUEST](state) {
    state.loadingReports = true;
    state.errorReports = "";
  },
  [QUEUE_CALLTIME_REPORT_SUCCESS](state, payload) {
    state.loadingReports = false;

    state.queueCallTimeData = payload;
  },
  [QUEUE_CALLTIME_REPORT_FAIL](state, errorReports) {
    state.loadingReports = false;
    state.errorReports = errorReports;
  },
  /* 
    QUEUE HOLDTIME REPORT INFO
  */
  [QUEUE_HOLDTIME_REPORT_REQUEST](state) {
    state.loadingReports = true;
    state.errorReports = "";
  },
  [QUEUE_HOLDTIME_REPORT_SUCCESS](state, payload) {
    state.loadingReports = false;

    state.queueHoldTimeData = payload;
  },
  [QUEUE_HOLDTIME_REPORT_FAIL](state, errorReports) {
    state.loadingReports = false;
    state.errorReports = errorReports;
  },
  /* 
    QUEUE MUTETIME REPORT INFO
  */
  [QUEUE_MUTETIME_REPORT_REQUEST](state) {
    state.loadingReports = true;
    state.errorReports = "";
  },
  [QUEUE_MUTETIME_REPORT_SUCCESS](state, payload) {
    state.loadingReports = false;

    state.queueMuteTimeData = payload;
  },
  [QUEUE_MUTETIME_REPORT_FAIL](state, errorReports) {
    state.loadingReports = false;
    state.errorReports = errorReports;
  },
  /* 
    QUEUE SERVICELEVEL REPORT INFO
  */
  [QUEUE_SERVICELEVEL_REPORT_REQUEST](state) {
    state.loadingReports = true;
    state.errorReports = "";
  },
  [QUEUE_SERVICELEVEL_REPORT_SUCCESS](state, payload) {
    state.loadingReports = false;

    state.queueServiceLevelData = payload;
  },
  [QUEUE_SERVICELEVEL_REPORT_FAIL](state, errorReports) {
    state.loadingReports = false;
    state.errorReports = errorReports;
  },
  /* 
    QUEUE FIRSTCALL REPORT INFO
  */
  [QUEUE_FIRSTCALL_REPORT_REQUEST](state) {
    state.loadingReports = true;
    state.errorReports = "";
  },
  [QUEUE_FIRSTCALL_REPORT_SUCCESS](state, payload) {
    state.loadingReports = false;

    state.queueFirstCallData = payload;
  },
  [QUEUE_FIRSTCALL_REPORT_FAIL](state, errorReports) {
    state.loadingReports = false;
    state.errorReports = errorReports;
  },

  /* 
    AGENT ANSWERED REPORT INFO
  */
  [AGENT_ANSWERED_REPORT_REQUEST](state) {
    state.loadingReports = true;
    state.errorReports = "";
  },
  [AGENT_ANSWERED_REPORT_SUCCESS](state, payload) {
    state.loadingReports = false;

    state.agentAnsweredData = payload;
  },
  [AGENT_ANSWERED_REPORT_FAIL](state, errorReports) {
    state.loadingReports = false;
    state.errorReports = errorReports;
  },
  /* 
    AGENT ABANDONED REPORT INFO
  */
  [AGENT_ABANDONED_REPORT_REQUEST](state) {
    state.loadingReports = true;
    state.errorReports = "";
  },
  [AGENT_ABANDONED_REPORT_SUCCESS](state, payload) {
    state.loadingReports = false;

    state.agentAbandonedData = payload;
  },
  [AGENT_ABANDONED_REPORT_FAIL](state, errorReports) {
    state.loadingReports = false;
    state.errorReports = errorReports;
  },
  /* 
    AGENT TOTALCALL REPORT INFO
  */
  [AGENT_TOTALCALL_REPORT_REQUEST](state) {
    state.loadingReports = true;
    state.errorReports = "";
  },
  [AGENT_TOTALCALL_REPORT_SUCCESS](state, payload) {
    state.loadingReports = false;

    state.agentTotalCallData = payload;
  },
  [AGENT_TOTALCALL_REPORT_FAIL](state, errorReports) {
    state.loadingReports = false;
    state.errorReports = errorReports;
  },
  /* 
    AGENT AHT REPORT INFO
  */
  [AGENT_AHT_REPORT_REQUEST](state) {
    state.loadingReports = true;
    state.errorReports = "";
  },
  [AGENT_AHT_REPORT_SUCCESS](state, payload) {
    state.loadingReports = false;

    state.agentAHTData = payload;
  },
  [AGENT_AHT_REPORT_FAIL](state, errorReports) {
    state.loadingReports = false;
    state.errorReports = errorReports;
  },
  /* 
    AGENT CALLTIME REPORT INFO
  */
  [AGENT_CALLTIME_REPORT_REQUEST](state) {
    state.loadingReports = true;
    state.errorReports = "";
  },
  [AGENT_CALLTIME_REPORT_SUCCESS](state, payload) {
    state.loadingReports = false;

    state.agentCallTimeData = payload;
  },
  [AGENT_CALLTIME_REPORT_FAIL](state, errorReports) {
    state.loadingReports = false;
    state.errorReports = errorReports;
  },
  /* 
    AGENT HOLDTIME REPORT INFO
  */
  [AGENT_HOLDTIME_REPORT_REQUEST](state) {
    state.loadingReports = true;
    state.errorReports = "";
  },
  [AGENT_HOLDTIME_REPORT_SUCCESS](state, payload) {
    state.loadingReports = false;

    state.agentHoldTimeData = payload;
  },
  [AGENT_HOLDTIME_REPORT_FAIL](state, errorReports) {
    state.loadingReports = false;
    state.errorReports = errorReports;
  },
  /* 
    AGENT MUTETIME REPORT INFO
  */
  [AGENT_MUTETIME_REPORT_REQUEST](state) {
    state.loadingReports = true;
    state.errorReports = "";
  },
  [AGENT_MUTETIME_REPORT_SUCCESS](state, payload) {
    state.loadingReports = false;

    state.agentMuteTimeData = payload;
  },
  [AGENT_MUTETIME_REPORT_FAIL](state, errorReports) {
    state.loadingReports = false;
    state.errorReports = errorReports;
  },
  /* 
    AGENT TIMETOANSWER REPORT INFO
  */
  [AGENT_TIMETOANSWER_REPORT_REQUEST](state) {
    state.loadingReports = true;
    state.errorReports = "";
  },
  [AGENT_TIMETOANSWER_REPORT_SUCCESS](state, payload) {
    state.loadingReports = false;

    state.agentTimeToAnswerData = payload;
  },
  [AGENT_TIMETOANSWER_REPORT_FAIL](state, errorReports) {
    state.loadingReports = false;
    state.errorReports = errorReports;
  },

  /*CLICK TO CALL START*/

  /* 
    AGENT ANSWERED REPORT INFO
  */
  [AGENT_CLICK_ANSWERED_REPORT_REQUEST](state) {
    state.loadingReports = true;
    state.errorReports = "";
  },
  [AGENT_CLICK_ANSWERED_REPORT_SUCCESS](state, payload) {
    state.loadingReports = false;

    state.agentClickAnsweredData = payload;
  },
  [AGENT_CLICK_ANSWERED_REPORT_FAIL](state, errorReports) {
    state.loadingReports = false;
    state.errorReports = errorReports;
  },
  /* 
      AGENT ABANDONED REPORT INFO
    */
  [AGENT_CLICK_ABANDONED_REPORT_REQUEST](state) {
    state.loadingReports = true;
    state.errorReports = "";
  },
  [AGENT_CLICK_ABANDONED_REPORT_SUCCESS](state, payload) {
    state.loadingReports = false;

    state.agentClickAbandonedData = payload;
  },
  [AGENT_CLICK_ABANDONED_REPORT_FAIL](state, errorReports) {
    state.loadingReports = false;
    state.errorReports = errorReports;
  },
  /* 
      AGENT TOTALCALL REPORT INFO
    */
  [AGENT_CLICK_TOTALCALL_REPORT_REQUEST](state) {
    state.loadingReports = true;
    state.errorReports = "";
  },
  [AGENT_CLICK_TOTALCALL_REPORT_SUCCESS](state, payload) {
    state.loadingReports = false;

    state.agentClickTotalCallData = payload;
  },
  [AGENT_CLICK_TOTALCALL_REPORT_FAIL](state, errorReports) {
    state.loadingReports = false;
    state.errorReports = errorReports;
  },
  /* 
      AGENT AHT REPORT INFO
    */
  [AGENT_CLICK_AHT_REPORT_REQUEST](state) {
    state.loadingReports = true;
    state.errorReports = "";
  },
  [AGENT_CLICK_AHT_REPORT_SUCCESS](state, payload) {
    state.loadingReports = false;

    state.agentClickAHTData = payload;
  },
  [AGENT_CLICK_AHT_REPORT_FAIL](state, errorReports) {
    state.loadingReports = false;
    state.errorReports = errorReports;
  },
  /* 
      AGENT CALLTIME REPORT INFO
    */
  [AGENT_CLICK_CALLTIME_REPORT_REQUEST](state) {
    state.loadingReports = true;
    state.errorReports = "";
  },
  [AGENT_CLICK_CALLTIME_REPORT_SUCCESS](state, payload) {
    state.loadingReports = false;

    state.agentClickCallTimeData = payload;
  },
  [AGENT_CLICK_CALLTIME_REPORT_FAIL](state, errorReports) {
    state.loadingReports = false;
    state.errorReports = errorReports;
  },
  /* 
      AGENT HOLDTIME REPORT INFO
    */
  [AGENT_CLICK_HOLDTIME_REPORT_REQUEST](state) {
    state.loadingReports = true;
    state.errorReports = "";
  },
  [AGENT_CLICK_HOLDTIME_REPORT_SUCCESS](state, payload) {
    state.loadingReports = false;

    state.agentClickHoldTimeData = payload;
  },
  [AGENT_CLICK_HOLDTIME_REPORT_FAIL](state, errorReports) {
    state.loadingReports = false;
    state.errorReports = errorReports;
  },
  /* 
      AGENT MUTETIME REPORT INFO
    */
  [AGENT_CLICK_MUTETIME_REPORT_REQUEST](state) {
    state.loadingReports = true;
    state.errorReports = "";
  },
  [AGENT_CLICK_MUTETIME_REPORT_SUCCESS](state, payload) {
    state.loadingReports = false;

    state.agentClickMuteTimeData = payload;
  },
  [AGENT_CLICK_MUTETIME_REPORT_FAIL](state, errorReports) {
    state.loadingReports = false;
    state.errorReports = errorReports;
  },
  /* 
      AGENT TIMETOANSWER REPORT INFO
    */
  [AGENT_CLICK_TIMETOANSWER_REPORT_REQUEST](state) {
    state.loadingReports = true;
    state.errorReports = "";
  },
  [AGENT_CLICK_TIMETOANSWER_REPORT_SUCCESS](state, payload) {
    state.loadingReports = false;

    state.agentClickTimeToAnswerData = payload;
  },
  [AGENT_CLICK_TIMETOANSWER_REPORT_FAIL](state, errorReports) {
    state.loadingReports = false;
    state.errorReports = errorReports;
  },

  /*CLICK TO CALL END */

  /*
    CDR REPORT INFO
  */
  [CDR_REPORT_REQUEST](state) {
    state.loadingReports = true;
    state.errorReports = "";
  },
  [CDR_REPORT_SUCCESS](state, payload) {
    state.loadingReports = false;
    state.cdrReport = payload.data;
    state.pageNumbers = payload.meta.last_page;
  },
  [CDR_REPORT_FAIL](state, errorReports) {
    state.loadingReports = false;
    state.errorReports = errorReports;
  },
  [CDR_REPORT_EXPORT_REQUEST](state) {
    state.loadingReports = true;
    state.errorReports = "";
  },
  [CDR_REPORT_EXPORT_SUCCESS](state, payload) {
    state.loadingReports = false;
    state.cdrReportExport = payload.data;
    state.pageNumbers = payload.meta.last_page;
  },
  [CDR_REPORT_EXPORT_FAIL](state, errorReports) {
    state.loadingReports = false;
    state.errorReports = errorReports;
  },
  /*
    AGENT ACTIVITY REPORT INFO
  */
  [AGENT_ACTIVITY_REPORT_REQUEST](state) {
    state.loadingReports = true;
    state.errorReports = "";
  },
  [AGENT_ACTIVITY_REPORT_SUCCESS](state, payload) {
    state.loadingReports = false;
    state.agentActivityReport = payload;
  },
  [AGENT_ACTIVITY_REPORT_FAIL](state, errorReports) {
    state.loadingReports = false;
    state.errorReports = errorReports;
  },

  /*
    AGENT ACTIVITY REPORT INFO
  */
  [AGENT_ACTIVITY_REPORT_RAW_REQUEST](state) {
    state.loadingReports = true;
    state.errorReports = "";
  },
  [AGENT_ACTIVITY_REPORT_RAW_SUCCESS](state, payload) {
    state.loadingReports = false;
    state.agentActivityReportRawBreaks = payload.filter((obj) => {
      return obj.type === "BREAK";
    });

    state.agentActivityReportRawSession = payload.filter((obj) => {
      return obj.type === "LOGIN";
    });
  },
  [AGENT_ACTIVITY_REPORT_RAW_FAIL](state, errorReports) {
    state.loadingReports = false;
    state.errorReports = errorReports;
  },
  /*
    IVR HIT REPORT INFO
  */
  [IVR_HIT_REPORT_REQUEST](state) {
    state.loadingReports = true;
    state.errorReports = "";
  },
  [IVR_HIT_REPORT_SUCCESS](state, payload) {
    state.loadingReports = false;
    state.ivrHitLabel = payload.map((label) => {
      return label.flow_name;
    });
    state.ivrHitData = payload.map((data) => {
      return data.ivr_hits;
    });
  },
  [IVR_HIT_REPORT_FAIL](state, errorReports) {
    state.loadingReports = false;
    state.errorReports = errorReports;
  },
  /*
    IVR BACKGROUND REPORT INFO
  */
  [IVR_BACKGROUND_REQUEST](state) {
    state.loadingReports = true;
    state.errorReports = "";
  },
  [IVR_BACKGROUND_SUCCESS](state, payload) {
    state.loadingReports = false;
    state.ivrBackground = payload;
    state.selectIvrBackground = payload.map((ivr) => {
      return { label: ivr.flow_name, value: ivr.id };
    });
  },
  [IVR_BACKGROUND_FAIL](state, errorReports) {
    state.loadingReports = false;
    state.errorReports = errorReports;
  },
  /* 
   GET CHATBOT CUMULATIVE
  */
  [GET_CHATBOT_REPORT_CUMULATIVE_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [GET_CHATBOT_REPORT_CUMULATIVE_SUCCESS](state, payload) {
    state.loadingCampaign = false;
    state.chatBotReportCumulative = payload;
    console.log("get chatbot hit reports Cumulative", payload);
  },
  [GET_CHATBOT_REPORT_CUMULATIVE_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },
};

const actions = {
  /* 
    CHANNEL TOTAL CHAT REPORT INFO 
  */
  async getChannelTotalChatReport({ commit }, payload) {
    commit(CHANNEL_TOTAL_CHAT_REPORT_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}Conversation/reports/channel_chat_volume_trend?date=${payload.date}&frequency=${payload.frequency}&kpi=TC`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("Get Channel Total Chat Volume Trend report config", config);

    axios(config)
      .then(function (response) {
        console.log(
          "Get Channel Total Chat Volume Trend report response",
          response.data
        );
        commit(CHANNEL_TOTAL_CHAT_REPORT_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(CHANNEL_TOTAL_CHAT_REPORT_FAIL, error.response.data);
        }
      });
  },
  /* 
    CHANNEL OPEN CHAT REPORT INFO 
  */
  async getChannelOpenChatReport({ commit }, payload) {
    commit(CHANNEL_OPEN_CHAT_REPORT_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}Conversation/reports/channel_chat_volume_trend?date=${payload.date}&frequency=${payload.frequency}&kpi=OC`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("Get Channel Open Chat Volume Trend report config", config);

    axios(config)
      .then(function (response) {
        console.log(
          "Get Channel Open Chat Volume Trend report response",
          response.data
        );
        commit(CHANNEL_OPEN_CHAT_REPORT_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(CHANNEL_OPEN_CHAT_REPORT_FAIL, error.response.data);
        }
      });
  },
  /* 
    CHANNEL CLOSED CHAT REPORT INFO 
  */
  async getChannelClosedChatReport({ commit }, payload) {
    commit(CHANNEL_TOTAL_CHAT_REPORT_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}Conversation/reports/channel_chat_volume_trend?date=${payload.date}&frequency=${payload.frequency}&kpi=CC`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("Get Channel Closed Chat Volume Trend report config", config);

    axios(config)
      .then(function (response) {
        console.log(
          "Get Channel Closed Chat Volume Trend report response",
          response.data
        );
        commit(CHANNEL_CLOSED_CHAT_REPORT_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(CHANNEL_CLOSED_CHAT_REPORT_FAIL, error.response.data);
        }
      });
  },
  /* 
    CHANNEL AHT CHAT REPORT INFO 
  */
  async getChannelAverageHandlingTimeChatReport({ commit }, payload) {
    commit(CHANNEL_AVERAGE_HANDLING_TIME_REPORT_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}Conversation/reports/channel_chat_volume_trend?date=${payload.date}&frequency=${payload.frequency}&kpi=AHT`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("Get Channel AHT Chat Volume Trend report config", config);

    axios(config)
      .then(function (response) {
        console.log(
          "Get Channel AHT Chat Volume Trend report response",
          response.data
        );
        commit(CHANNEL_AVERAGE_HANDLING_TIME_REPORT_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(
            CHANNEL_AVERAGE_HANDLING_TIME_REPORT_FAIL,
            error.response.data
          );
        }
      });
  },
  /* 
    CHANNEL AQT CHAT REPORT INFO 
  */
  async getChannelAverageQueueTimeChatReport({ commit }, payload) {
    commit(CHANNEL_AVERAGE_QUEUE_TIME_REPORT_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}Conversation/reports/channel_chat_volume_trend?date=${payload.date}&frequency=${payload.frequency}&kpi=AQT`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("Get Channel AQT Chat Volume Trend report config", config);

    axios(config)
      .then(function (response) {
        console.log(
          "Get Channel AQT Chat Volume Trend report response",
          response.data
        );
        commit(CHANNEL_AVERAGE_QUEUE_TIME_REPORT_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(CHANNEL_AVERAGE_QUEUE_TIME_REPORT_FAIL, error.response.data);
        }
      });
  },
  /* 
    QUEUE TOTAL CHAT REPORT INFO 
  */
  async getQueueTotalChatReport({ commit }, payload) {
    commit(QUEUE_TOTAL_CHAT_REPORT_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}Conversation/reports/queue_chat_volume_trend?date=${payload.date}&frequency=${payload.frequency}&kpi=TC`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("Get Queue Total Chat Volume Trend report config", config);

    axios(config)
      .then(function (response) {
        console.log(
          "Get Queue Total Chat Volume Trend report response",
          response.data
        );
        commit(QUEUE_TOTAL_CHAT_REPORT_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(QUEUE_TOTAL_CHAT_REPORT_FAIL, error.response.data);
        }
      });
  },
  /* 
      QUEUE OPEN CHAT REPORT INFO 
    */
  async getQueueOpenChatReport({ commit }, payload) {
    commit(QUEUE_OPEN_CHAT_REPORT_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}Conversation/reports/queue_chat_volume_trend?date=${payload.date}&frequency=${payload.frequency}&kpi=OC`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("Get Queue Open Chat Volume Trend report config", config);

    axios(config)
      .then(function (response) {
        console.log(
          "Get Queue Open Chat Volume Trend report response",
          response.data
        );
        commit(QUEUE_OPEN_CHAT_REPORT_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(QUEUE_OPEN_CHAT_REPORT_FAIL, error.response.data);
        }
      });
  },
  /* 
      QUEUE CLOSED CHAT REPORT INFO 
    */
  async getQueueClosedChatReport({ commit }, payload) {
    commit(QUEUE_TOTAL_CHAT_REPORT_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}Conversation/reports/queue_chat_volume_trend?date=${payload.date}&frequency=${payload.frequency}&kpi=CC`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("Get Queue Closed Chat Volume Trend report config", config);

    axios(config)
      .then(function (response) {
        console.log(
          "Get Queue Closed Chat Volume Trend report response",
          response.data
        );
        commit(QUEUE_CLOSED_CHAT_REPORT_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(QUEUE_CLOSED_CHAT_REPORT_FAIL, error.response.data);
        }
      });
  },
  /* 
      QUEUE AHT CHAT REPORT INFO 
    */
  async getQueueAverageHandlingTimeChatReport({ commit }, payload) {
    commit(QUEUE_AVERAGE_HANDLING_TIME_REPORT_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}Conversation/reports/queue_chat_volume_trend?date=${payload.date}&frequency=${payload.frequency}&kpi=AHT`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("Get Queue AHT Chat Volume Trend report config", config);

    axios(config)
      .then(function (response) {
        console.log(
          "Get Queue AHT Chat Volume Trend report response",
          response.data
        );
        commit(QUEUE_AVERAGE_HANDLING_TIME_REPORT_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(QUEUE_AVERAGE_HANDLING_TIME_REPORT_FAIL, error.response.data);
        }
      });
  },
  /* 
      QUEUE AQT CHAT REPORT INFO 
    */
  async getQueueAverageQueueTimeChatReport({ commit }, payload) {
    commit(QUEUE_AVERAGE_QUEUE_TIME_REPORT_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}Conversation/reports/queue_chat_volume_trend?date=${payload.date}&frequency=${payload.frequency}&kpi=AQT`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("Get Queue AQT Chat Volume Trend report config", config);

    axios(config)
      .then(function (response) {
        console.log(
          "Get Queue AQT Chat Volume Trend report response",
          response.data
        );
        commit(QUEUE_AVERAGE_QUEUE_TIME_REPORT_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(QUEUE_AVERAGE_QUEUE_TIME_REPORT_FAIL, error.response.data);
        }
      });
  },
  /* 
      AGENT TOTAL CHAT REPORT INFO 
    */
  async getAgentTotalChatReport({ commit }, payload) {
    commit(AGENT_TOTAL_CHAT_REPORT_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}Conversation/reports/agent_chat_volume_trend?date=${payload.date}&frequency=${payload.frequency}&kpi=TC`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("Get Agent Total Chat Volume Trend report config", config);

    axios(config)
      .then(function (response) {
        console.log(
          "Get Agent Chat Volume Trend report response",
          response.data
        );
        commit(AGENT_TOTAL_CHAT_REPORT_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(AGENT_TOTAL_CHAT_REPORT_FAIL, error.response.data);
        }
      });
  },
  /* 
        AGENT OPEN CHAT REPORT INFO 
      */
  async getAgentOpenChatReport({ commit }, payload) {
    commit(AGENT_OPEN_CHAT_REPORT_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}Conversation/reports/agent_chat_volume_trend?date=${payload.date}&frequency=${payload.frequency}&kpi=OC`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("Get Agent Open Chat Volume Trend report config", config);

    axios(config)
      .then(function (response) {
        console.log(
          "Get Agent Open Chat Volume Trend report response",
          response.data
        );
        commit(AGENT_OPEN_CHAT_REPORT_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(AGENT_OPEN_CHAT_REPORT_FAIL, error.response.data);
        }
      });
  },
  /* 
        AGENT CLOSED CHAT REPORT INFO 
      */
  async getAgentClosedChatReport({ commit }, payload) {
    commit(AGENT_TOTAL_CHAT_REPORT_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}Conversation/reports/agent_chat_volume_trend?date=${payload.date}&frequency=${payload.frequency}&kpi=CC`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("Get Agent Closed Chat Volume Trend report config", config);

    axios(config)
      .then(function (response) {
        console.log(
          "Get Agent Closed Chat Volume Trend report response",
          response.data
        );
        commit(AGENT_CLOSED_CHAT_REPORT_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(AGENT_CLOSED_CHAT_REPORT_FAIL, error.response.data);
        }
      });
  },
  /* 
        AGENT AHT CHAT REPORT INFO 
      */
  async getAgentAverageHandlingTimeChatReport({ commit }, payload) {
    commit(AGENT_AVERAGE_HANDLING_TIME_REPORT_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}Conversation/reports/agent_chat_volume_trend?date=${payload.date}&frequency=${payload.frequency}&kpi=AHT`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("Get Agent AHT Chat Volume Trend report config", config);

    axios(config)
      .then(function (response) {
        console.log(
          "Get Agent AHT Chat Volume Trend report response",
          response.data
        );
        commit(AGENT_AVERAGE_HANDLING_TIME_REPORT_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(AGENT_AVERAGE_HANDLING_TIME_REPORT_FAIL, error.response.data);
        }
      });
  },
  /* 
        AGENT AQT CHAT REPORT INFO 
      */
  async getAgentAverageQueueTimeChatReport({ commit }, payload) {
    commit(AGENT_AVERAGE_QUEUE_TIME_REPORT_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}Conversation/reports/agent_chat_volume_trend?date=${payload.date}&frequency=${payload.frequency}&kpi=AQT`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("Get Agent AQT Chat Volume Trend report config", config);

    axios(config)
      .then(function (response) {
        console.log(
          "Get Agent AQT Chat Volume Trend report response",
          response.data
        );
        commit(AGENT_AVERAGE_QUEUE_TIME_REPORT_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(AGENT_AVERAGE_QUEUE_TIME_REPORT_FAIL, error.response.data);
        }
      });
  },
  /* 
    CHATBOT HIT INFO 
  */
  async getChatBotHitOptionsReport({ commit }) {
    commit(GET_CHAT_BOT_FORMS_ITEMS_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}Conversation/reports/get_chat_bot_option`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: {},
    };
    console.log("get CHAT_BOT form ChatBot Hit items config", config);

    axios(config)
      .then(function (response) {
        console.log("get CHAT_BOT form items response", response.data);
        commit(GET_CHAT_BOT_FORMS_ITEMS_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(GET_CHAT_BOT_FORMS_ITEMS_FAIL, error.response.data);
        }
      });
  },
  /* 
    QUEUE ANSWERED REPORT INFO 
  */
  async getQueueAnsweredReport({ commit }, payload) {
    commit(QUEUE_ANSWERED_REPORT_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}call_report/queue_report?frequency=${payload.frequency}&date=${payload.date}&kpi=ANSWERED`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("get queue ANSWERED report config", config);

    axios(config)
      .then(function (response) {
        console.log("get queue ANSWERED report response", response.data);
        commit(QUEUE_ANSWERED_REPORT_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(QUEUE_ANSWERED_REPORT_FAIL, error.response.data);
        }
      });
  },
  /* 
    QUEUE ABANDONED REPORT INFO 
  */
  async getQueueAbandonedReport({ commit }, payload) {
    commit(QUEUE_ABANDONED_REPORT_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}call_report/queue_report?frequency=${payload.frequency}&date=${payload.date}&kpi=ABANDONED`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("get queue ABANDONED report config", config);

    axios(config)
      .then(function (response) {
        console.log("get queue ABANDONED report response", response.data);
        commit(QUEUE_ABANDONED_REPORT_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(QUEUE_ABANDONED_REPORT_FAIL, error.response.data);
        }
      });
  },
  /* 
    QUEUE TOTALCALL REPORT INFO 
  */
  async getQueueTotalCallReport({ commit }, payload) {
    commit(QUEUE_TOTALCALL_REPORT_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}call_report/queue_report?frequency=${payload.frequency}&date=${payload.date}&kpi=TOTALCALL`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("get queue TOTALCALL report config", config);

    axios(config)
      .then(function (response) {
        console.log("get queue TOTALCALL report response", response.data);
        commit(QUEUE_TOTALCALL_REPORT_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(QUEUE_TOTALCALL_REPORT_FAIL, error.response.data);
        }
      });
  },
  /* 
    QUEUE AHT REPORT INFO 
  */
  async getQueueAHTReport({ commit }, payload) {
    commit(QUEUE_AHT_REPORT_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}call_report/queue_report?frequency=${payload.frequency}&date=${payload.date}&kpi=AHT`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("get queue AHT report config", config);

    axios(config)
      .then(function (response) {
        console.log("get queue AHT report response", response.data);
        commit(QUEUE_AHT_REPORT_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(QUEUE_AHT_REPORT_FAIL, error.response.data);
        }
      });
  },
  /* 
    QUEUE CALLTIME REPORT INFO 
  */
  async getQueueCallTimeReport({ commit }, payload) {
    commit(QUEUE_CALLTIME_REPORT_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}call_report/queue_report?frequency=${payload.frequency}&date=${payload.date}&kpi=CALLTIME`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("get queue CALLTIME report config", config);

    axios(config)
      .then(function (response) {
        console.log("get queue CALLTIME report response", response.data);
        commit(QUEUE_CALLTIME_REPORT_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(QUEUE_CALLTIME_REPORT_FAIL, error.response.data);
        }
      });
  },
  /* 
    QUEUE HOLDTIME REPORT INFO 
  */
  async getQueueHoldTimeReport({ commit }, payload) {
    commit(QUEUE_HOLDTIME_REPORT_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}call_report/queue_report?frequency=${payload.frequency}&date=${payload.date}&kpi=HOLDTIME`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("get queue HOLDTIME report config", config);

    axios(config)
      .then(function (response) {
        console.log("get queue HOLDTIME report response", response.data);
        commit(QUEUE_HOLDTIME_REPORT_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(QUEUE_HOLDTIME_REPORT_FAIL, error.response.data);
        }
      });
  },
  /* 
    QUEUE MUTETIME REPORT INFO 
  */
  async getQueueMuteTimeReport({ commit }, payload) {
    commit(QUEUE_MUTETIME_REPORT_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}call_report/queue_report?frequency=${payload.frequency}&date=${payload.date}&kpi=MUTETIME`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("get queue MUTETIME report config", config);

    axios(config)
      .then(function (response) {
        console.log("get queue MUTETIME report response", response.data);
        commit(QUEUE_MUTETIME_REPORT_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(QUEUE_MUTETIME_REPORT_FAIL, error.response.data);
        }
      });
  },
  /* 
    QUEUE SERVICELEVEL REPORT INFO 
  */
  async getQueueServiceLevelReport({ commit }, payload) {
    commit(QUEUE_SERVICELEVEL_REPORT_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}call_report/queue_report?frequency=${payload.frequency}&date=${payload.date}&kpi=SERVICELEVEL`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("get queue SERVICELEVEL report config", config);

    axios(config)
      .then(function (response) {
        console.log("get queue SERVICELEVEL report response", response.data);
        commit(QUEUE_SERVICELEVEL_REPORT_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(QUEUE_SERVICELEVEL_REPORT_FAIL, error.response.data);
        }
      });
  },
  /* 
    QUEUE FIRSTCALL REPORT INFO 
  */
  async getQueueFirstCallReport({ commit }, payload) {
    commit(QUEUE_FIRSTCALL_REPORT_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}call_report/queue_report?frequency=${payload.frequency}&date=${payload.date}&kpi=FIRSTCALL`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("get queue FIRSTCALL report config", config);

    axios(config)
      .then(function (response) {
        console.log("get queue FIRSTCALL report response", response.data);
        commit(QUEUE_FIRSTCALL_REPORT_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(QUEUE_FIRSTCALL_REPORT_FAIL, error.response.data);
        }
      });
  },

  /* 
    AGENT ANSWERED REPORT INFO 
  */
  async getAgentAnsweredReport({ commit }, payload) {
    commit(AGENT_ANSWERED_REPORT_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}call_report/get_agent_call_report?frequency=${payload.frequency}&date=${payload.date}&kpi=ANSWERED`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("get AGENT ANSWERED report config", config);

    axios(config)
      .then(function (response) {
        console.log("get AGENT ANSWERED report response", response.data);
        commit(AGENT_ANSWERED_REPORT_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(AGENT_ANSWERED_REPORT_FAIL, error.response.data);
        }
      });
  },
  /* 
    AGENT ABANDONED REPORT INFO 
  */
  async getAgentAbandonedReport({ commit }, payload) {
    commit(AGENT_ABANDONED_REPORT_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}call_report/get_agent_call_report?frequency=${payload.frequency}&date=${payload.date}&kpi=ABANDONED`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("get AGENT ABANDONED report config", config);

    axios(config)
      .then(function (response) {
        console.log("get AGENT ABANDONED report response", response.data);
        commit(AGENT_ABANDONED_REPORT_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(AGENT_ABANDONED_REPORT_FAIL, error.response.data);
        }
      });
  },
  /* 
    AGENT TOTALCALL REPORT INFO 
  */
  async getAgentTotalCallReport({ commit }, payload) {
    commit(AGENT_TOTALCALL_REPORT_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}call_report/get_agent_call_report?frequency=${payload.frequency}&date=${payload.date}&kpi=TOTALCALL`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("get AGENT TOTALCALL report config", config);

    axios(config)
      .then(function (response) {
        console.log("get AGENT TOTALCALL report response", response.data);
        commit(AGENT_TOTALCALL_REPORT_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(AGENT_TOTALCALL_REPORT_FAIL, error.response.data);
        }
      });
  },
  /* 
    AGENT AHT REPORT INFO 
  */
  async getAgentAHTReport({ commit }, payload) {
    commit(AGENT_AHT_REPORT_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}call_report/get_agent_call_report?frequency=${payload.frequency}&date=${payload.date}&kpi=AHT`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("get AGENT AHT report config", config);

    axios(config)
      .then(function (response) {
        console.log("get AGENT AHT report response", response.data);
        commit(AGENT_AHT_REPORT_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(AGENT_AHT_REPORT_FAIL, error.response.data);
        }
      });
  },
  /* 
    AGENT CALLTIME REPORT INFO 
  */
  async getAgentCallTimeReport({ commit }, payload) {
    commit(AGENT_CALLTIME_REPORT_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}call_report/get_agent_call_report?frequency=${payload.frequency}&date=${payload.date}&kpi=CALLTIME`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("get AGENT CALLTIME report config", config);

    axios(config)
      .then(function (response) {
        console.log("get AGENT CALLTIME report response", response.data);
        commit(AGENT_CALLTIME_REPORT_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(AGENT_CALLTIME_REPORT_FAIL, error.response.data);
        }
      });
  },
  /* 
    AGENT HOLDTIME REPORT INFO 
  */
  async getAgentHoldTimeReport({ commit }, payload) {
    commit(AGENT_HOLDTIME_REPORT_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}call_report/get_agent_call_report?frequency=${payload.frequency}&date=${payload.date}&kpi=HOLDTIME`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("get AGENT HOLDTIME report config", config);

    axios(config)
      .then(function (response) {
        console.log("get AGENT HOLDTIME report response", response.data);
        commit(AGENT_HOLDTIME_REPORT_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(AGENT_HOLDTIME_REPORT_FAIL, error.response.data);
        }
      });
  },
  /* 
    AGENT MUTETIME REPORT INFO 
  */
  async getAgentMuteTimeReport({ commit }, payload) {
    commit(AGENT_MUTETIME_REPORT_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}call_report/get_agent_call_report?frequency=${payload.frequency}&date=${payload.date}&kpi=MUTETIME`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("get AGENT MUTETIME report config", config);

    axios(config)
      .then(function (response) {
        console.log("get AGENT MUTETIME report response", response.data);
        commit(AGENT_MUTETIME_REPORT_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(AGENT_MUTETIME_REPORT_FAIL, error.response.data);
        }
      });
  },
  /* 
    AGENT TIMETOANSWER REPORT INFO 
  */
  async getAgentTimeToAnswerReport({ commit }, payload) {
    commit(AGENT_TIMETOANSWER_REPORT_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}call_report/get_agent_call_report?frequency=${payload.frequency}&date=${payload.date}&kpi=TIMETOANSWER`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("get AGENT TIMETOANSWER report config", config);

    axios(config)
      .then(function (response) {
        console.log("get AGENT TIMETOANSWER report response", response.data);
        commit(AGENT_TIMETOANSWER_REPORT_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(AGENT_TIMETOANSWER_REPORT_FAIL, error.response.data);
        }
      });
  },

  /*Click to call start */

  /* 
    AGENT ANSWERED REPORT INFO 
  */
  async getAgentClickAnsweredReport({ commit }, payload) {
    commit(AGENT_CLICK_ANSWERED_REPORT_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}call_report/click_to_call_report?frequency=${payload.frequency}&date=${payload.date}&kpi=ANSWERED`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("get AGENT ANSWERED report config", config);

    axios(config)
      .then(function (response) {
        console.log("get AGENT ANSWERED report response", response.data);
        commit(AGENT_CLICK_ANSWERED_REPORT_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(AGENT_CLICK_ANSWERED_REPORT_FAIL, error.response.data);
        }
      });
  },
  /* 
      AGENT ABANDONED REPORT INFO 
    */
  async getAgentClickAbandonedReport({ commit }, payload) {
    commit(AGENT_CLICK_ABANDONED_REPORT_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}call_report/click_to_call_report?frequency=${payload.frequency}&date=${payload.date}&kpi=NOANSWER`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("get AGENT ABANDONED report config", config);

    axios(config)
      .then(function (response) {
        console.log("get AGENT ABANDONED report response", response.data);
        commit(AGENT_CLICK_ABANDONED_REPORT_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(AGENT_CLICK_ABANDONED_REPORT_FAIL, error.response.data);
        }
      });
  },
  /* 
      AGENT TOTALCALL REPORT INFO 
    */
  async getAgentClickTotalCallReport({ commit }, payload) {
    commit(AGENT_CLICK_TOTALCALL_REPORT_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}call_report/click_to_call_report?frequency=${payload.frequency}&date=${payload.date}&kpi=TOTALCALL`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("get AGENT TOTALCALL report config", config);

    axios(config)
      .then(function (response) {
        console.log("get AGENT TOTALCALL report response", response.data);
        commit(AGENT_CLICK_TOTALCALL_REPORT_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(AGENT_CLICK_TOTALCALL_REPORT_FAIL, error.response.data);
        }
      });
  },
  /* 
      AGENT AHT REPORT INFO 
    */
  async getAgentClickAHTReport({ commit }, payload) {
    commit(AGENT_CLICK_AHT_REPORT_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}call_report/click_to_call_report?frequency=${payload.frequency}&date=${payload.date}&kpi=AHT`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("get AGENT AHT report config", config);

    axios(config)
      .then(function (response) {
        console.log("get AGENT AHT report response", response.data);
        commit(AGENT_CLICK_AHT_REPORT_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(AGENT_CLICK_AHT_REPORT_FAIL, error.response.data);
        }
      });
  },
  /* 
      AGENT CALLTIME REPORT INFO 
    */
  async getAgentClickCallTimeReport({ commit }, payload) {
    commit(AGENT_CLICK_CALLTIME_REPORT_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}call_report/click_to_call_report?frequency=${payload.frequency}&date=${payload.date}&kpi=CALLTIME`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("get AGENT CALLTIME report config", config);

    axios(config)
      .then(function (response) {
        console.log("get AGENT CALLTIME report response", response.data);
        commit(AGENT_CLICK_CALLTIME_REPORT_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(AGENT_CLICK_CALLTIME_REPORT_FAIL, error.response.data);
        }
      });
  },
  /* 
      AGENT HOLDTIME REPORT INFO 
    */
  async getAgentClickHoldTimeReport({ commit }, payload) {
    commit(AGENT_CLICK_HOLDTIME_REPORT_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}call_report/click_to_call_report?frequency=${payload.frequency}&date=${payload.date}&kpi=HOLDTIME`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("get AGENT HOLDTIME report config", config);

    axios(config)
      .then(function (response) {
        console.log("get AGENT HOLDTIME report response", response.data);
        commit(AGENT_CLICK_HOLDTIME_REPORT_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(AGENT_CLICK_HOLDTIME_REPORT_FAIL, error.response.data);
        }
      });
  },
  /* 
      AGENT MUTETIME REPORT INFO 
    */
  async getAgentClickMuteTimeReport({ commit }, payload) {
    commit(AGENT_CLICK_MUTETIME_REPORT_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}call_report/click_to_call_report?frequency=${payload.frequency}&date=${payload.date}&kpi=MUTETIME`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("get AGENT MUTETIME report config", config);

    axios(config)
      .then(function (response) {
        console.log("get AGENT MUTETIME report response", response.data);
        commit(AGENT_CLICK_MUTETIME_REPORT_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(AGENT_CLICK_MUTETIME_REPORT_FAIL, error.response.data);
        }
      });
  },
  /* 
      AGENT TIMETOANSWER REPORT INFO 
    */
  async getAgentClickTimeToAnswerReport({ commit }, payload) {
    commit(AGENT_CLICK_TIMETOANSWER_REPORT_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}call_report/click_to_call_report?frequency=${payload.frequency}&date=${payload.date}&kpi=TIMETOANSWER`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("get AGENT TIMETOANSWER report config", config);

    axios(config)
      .then(function (response) {
        console.log("get AGENT TIMETOANSWER report response", response.data);
        commit(AGENT_CLICK_TIMETOANSWER_REPORT_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(AGENT_CLICK_TIMETOANSWER_REPORT_FAIL, error.response.data);
        }
      });
  },

  /*click to call end */

  /*
    CDR REPORT INFO
  */
  async getCdrReport({ commit }, payload) {
    commit(CDR_REPORT_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}call_report/cdr_report?from=${payload.start}&to=${payload.end}&queue_id=${payload.queue_id}&agent_id=${payload.agent_id}&page=${payload.page}&phone_number=${payload.phone_number}`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("get cdr report config", config);

    axios(config)
      .then(function (response) {
        console.log("get cdr report response", response.data);
        commit(CDR_REPORT_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(CDR_REPORT_FAIL, error.response.data);
        }
      });
  },

  /*
    CDR REPORT INFO
  */
  async getCdrReportExport({ commit }, payload) {
    commit(CDR_REPORT_EXPORT_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}call_report/cdr_report_export?from=${payload.start}&to=${payload.end}&queue_id=${payload.queue_id}&agent_id=${payload.agent_id}&page=${payload.page}`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("get cdr report config", config);

    axios(config)
      .then(function (response) {
        console.log("get cdr report response", response.data);
        commit(CDR_REPORT_EXPORT_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(CDR_REPORT_EXPORT_FAIL, error.response.data);
        }
      });
  },
  /*
    AGENT ACTIVITY REPORT INFO
  */
  async getAgentActivityReport({ commit }, payload) {
    commit(AGENT_ACTIVITY_REPORT_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}call_report/get_agent_activity_report?from=${payload.start}&to=${payload.end}`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("get agent activity report config", config);

    axios(config)
      .then(function (response) {
        console.log("get agent activity report response", response.data);
        commit(AGENT_ACTIVITY_REPORT_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(AGENT_ACTIVITY_REPORT_FAIL, error.response.data);
        }
      });
  },

  /*
    AGENT ACTIVITY REPORT INFO
  */
  async getAgentActivityReportRaw({ commit }, payload) {
    commit(AGENT_ACTIVITY_REPORT_RAW_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}call_report/get_agent_login_detail?from=${payload.start}&to=${payload.end}`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("get agent activity report config", config);

    axios(config)
      .then(function (response) {
        console.log(
          "get agent activity report raw response",
          response.data.agent_activity
        );
        commit(AGENT_ACTIVITY_REPORT_RAW_SUCCESS, response.data.agent_activity);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(AGENT_ACTIVITY_REPORT_RAW_FAIL, error.response.data);
        }
      });
  },
  /*
    IVR HIT REPORT INFO
  */
  async getIvrHitReport({ commit }, payload) {
    commit(IVR_HIT_REPORT_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}call_report/get_ivr_hit_report?from=${payload.start}&to=${payload.end}&ivr_option=${payload.option}`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("ivr hit report config", config);

    axios(config)
      .then(function (response) {
        console.log("ivr hit report response", response.data);
        commit(IVR_HIT_REPORT_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(IVR_HIT_REPORT_FAIL, error.response.data);
        }
      });
  },
  /*
    IVR BACKGROUND INFO
  */
  async getIvrBackground({ commit }, payload) {
    commit(IVR_BACKGROUND_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}call_report/get_ivr_background`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("ivr background config", config);

    axios(config)
      .then(function (response) {
        console.log("ivr background response", response.data);
        commit(IVR_BACKGROUND_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(IVR_BACKGROUND_FAIL, error.response.data);
        }
      });
  },
  /* 
      GET CHATBOT REPORT TABLE
    */
  async getChatBotHitReportTable({ commit }, payload) {
    commit(GET_CHATBOT_REPORT_CUMULATIVE_REQUEST);
    var config = {
      method: "get",
      url: `${baseUrl}Conversation/reports/get_chat_bot_hit_report?from=${payload.start}&to=${payload.end}&flow_id=${payload.flow_id}`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("create chatbot config", config);

    axios(config)
      .then(function (response) {
        console.log("get chatbot hit reports table", response.data);
        commit(GET_CHATBOT_REPORT_CUMULATIVE_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(GET_CHATBOT_REPORT_CUMULATIVE_FAIL, error.response.data);
        }
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  strict: process.env.NODE_ENV != "production",
};
