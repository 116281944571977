import axios from "axios";

const baseUrl = process.env.VUE_APP_API_URL;

export const CONTACTCENTER_AGENT_DASHBOARD_REQUEST =
  "CONTACTCENTER_AGENT_DASHBOARD_REQUEST";
export const CONTACTCENTER_AGENT_DASHBOARD_SUCCESS =
  "CONTACTCENTER_AGENT_DASHBOARD_SUCCESS";
export const CONTACTCENTER_AGENT_DASHBOARD_FAIL =
  "CONTACTCENTER_AGENT_DASHBOARD_FAIL";

export const CONTACTCENTER_ADMIN_DASHBOARD_REQUEST =
  "CONTACTCENTER_ADMIN_DASHBOARD_REQUEST";
export const CONTACTCENTER_ADMIN_DASHBOARD_SUCCESS =
  "CONTACTCENTER_ADMIN_DASHBOARD_SUCCESS";
export const CONTACTCENTER_ADMIN_DASHBOARD_FAIL =
  "CONTACTCENTER_ADMIN_DASHBOARD_FAIL";

const mutations = {
  /* 
    Agent Dashboard
  */
  [CONTACTCENTER_AGENT_DASHBOARD_REQUEST](state) {
    state.loadingCalls = true;
    state.errorCalls = "";
  },
  [CONTACTCENTER_AGENT_DASHBOARD_SUCCESS](state, payload) {
    state.callcenterAgentDashboard = payload;
    state.loadingCalls = false;
  },
  [CONTACTCENTER_AGENT_DASHBOARD_FAIL](state, errorCalls) {
    state.loadingCalls = false;
    state.errorCalls = errorCalls;
  },

  /* 
    Admin Dashboard
  */
  [CONTACTCENTER_ADMIN_DASHBOARD_REQUEST](state) {
    state.loadingCalls = true;
    state.errorCalls = "";
  },
  [CONTACTCENTER_ADMIN_DASHBOARD_SUCCESS](state, payload) {
    state.callCenterAdminDashboard = payload;
    state.loadingCalls = false;
  },
  [CONTACTCENTER_ADMIN_DASHBOARD_FAIL](state, errorCalls) {
    state.loadingCalls = false;
    state.errorCalls = errorCalls;
  },
};

const state = {
  callcenterAgentDashboard: [],
  callCenterAdminDashboard: [],
};

const getters = {
  callcenterAgentDashboard: (state) => state.callcenterAgentDashboard,
  loadingCalls: (state) => state.loadingCalls,
  errorCalls: (state) => state.errorCalls,

  callCenterAdminDashboard: (state) => state.callCenterAdminDashboard,
};

const actions = {
  async getCallcenterAgentDashboard({ commit }, payload) {
    console.log("getCallcenterAgentDashboard");
    commit(CONTACTCENTER_AGENT_DASHBOARD_REQUEST);
    var config = {
      method: "get",
      url: `${baseUrl}call_action/get_agent_dashboard`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("Agent dashboard Config", config);

    axios(config)
      .then(function (response) {
        commit(CONTACTCENTER_AGENT_DASHBOARD_SUCCESS, response.data);
        console.log("Agent dashboard Response", response.data);
      })
      .catch(function (error) {
        console.log(error);
        commit(CONTACTCENTER_AGENT_DASHBOARD_FAIL);
      });
  },

  async getCallcenterAdminDashboard({ commit }, payload) {
    commit(CONTACTCENTER_ADMIN_DASHBOARD_REQUEST);
    var config = {
      method: "get",
      url: `${baseUrl}call_action/get_admin_dashboard`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("Admin dashboard Config", config);

    axios(config)
      .then(function (response) {
        commit(CONTACTCENTER_ADMIN_DASHBOARD_SUCCESS, response.data);
        console.log("Admin dashboard Response", response.data);
      })
      .catch(function (error) {
        console.log(error);
        commit(CONTACTCENTER_ADMIN_DASHBOARD_FAIL);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  strict: process.env.NODE_ENV != "production",
};
