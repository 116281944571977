import axios from "axios";
import router from "@/router";
const baseUrl = process.env.VUE_APP_API_URL;

export const CREATE_CAMPAIGN_REQUEST = "CREATE_CAMPAIGN_REQUEST";
export const CREATE_CAMPAIGN_SUCCESS = "CREATE_CAMPAIGN_SUCCESS";
export const CREATE_CAMPAIGN_FAIL = "CREATE_CAMPAIGN_FAIL";

export const GET_CAMPAIGN_REPORT_TABLE_REQUEST =
  "GET_CAMPAIGN_REPORT_TABLE_REQUEST";
export const GET_CAMPAIGN_REPORT_TABLE_SUCCESS =
  "GET_CAMPAIGN_REPORT_TABLE_SUCCESS";
export const GET_CAMPAIGN_REPORT_TABLE_FAIL = "GET_CAMPAIGN_REPORT_TABLE_FAIL";

export const GET_SURVEY_REPORT_TABLE_REQUEST =
  "GET_SURVEY_REPORT_TABLE_REQUEST";
export const GET_SURVEY_REPORT_TABLE_SUCCESS =
  "GET_SURVEY_REPORT_TABLE_SUCCESS";
export const GET_SURVEY_REPORT_TABLE_FAIL = "GET_SURVEY_REPORT_TABLE_FAIL";

export const GET_SURVEY_REPORT_CUMULATIVE_REQUEST =
  "GET_SURVEY_REPORT_CUMULATIVE_REQUEST";
export const GET_SURVEY_REPORT_CUMULATIVE_SUCCESS =
  "GET_SURVEY_REPORT_CUMULATIVE_SUCCESS";
export const GET_SURVEY_REPORT_CUMULATIVE_FAIL =
  "GET_SURVEY_REPORT_CUMULATIVE_FAIL";

export const CREATE_CAMPAIGN_SURVEY_REQUEST = "CREATE_CAMPAIGN_SURVEY_REQUEST";
export const CREATE_CAMPAIGN_SURVEY_SUCCESS = "CREATE_CAMPAIGN_SURVEY_SUCCESS";
export const CREATE_CAMPAIGN_SURVEY_FAIL = "CREATE_CAMPAIGN_SURVEY_FAIL";
export const GET_CAMPAIGN_TYPE_REQUEST = "GET_CAMPAIGN_TYPE_REQUEST";
export const GET_CAMPAIGN_TYPE_SUCCESS = "GET_CAMPAIGN_TYPE_SUCCESS";
export const GET_CAMPAIGN_TYPE_FAIL = "GET_CAMPAIGN_TYPE_FAIL";
export const GET_CAMPAIGN_REQUEST = "GET_CAMPAIGN_REQUEST";
export const GET_CAMPAIGN_SUCCESS = "GET_CAMPAIGN_SUCCESS";
export const GET_CAMPAIGN_FAIL = "GET_CAMPAIGN_FAIL";
export const GET_SENDER_ID_REQUEST = "GET_SENDER_ID_REQUEST";
export const GET_SENDER_ID_SUCCESS = "GET_SENDER_ID_SUCCESS";
export const GET_SENDER_ID_FAIL = "GET_SENDER_ID_FAIL";
export const GET_QUEUE_FROM_CAMPAIGN_REQUEST =
  "GET_QUEUE_FROM_CAMPAIGN_REQUEST";
export const GET_QUEUE_FROM_CAMPAIGN_SUCCESS =
  "GET_QUEUE_FROM_CAMPAIGN_SUCCESS";
export const GET_QUEUE_FROM_CAMPAIGN_FAIL = "GET_QUEUE_FROM_CAMPAIGN_FAIL";
export const GET_CAMPAIGN_CONTACT_REQUEST = "GET_CAMPAIGN_CONTACT_REQUEST";
export const GET_CAMPAIGN_CONTACT_SUCCESS = "GET_CAMPAIGN_CONTACT_SUCCESS";
export const GET_CAMPAIGN_CONTACT_FAIL = "GET_CAMPAIGN_CONTACT_FAIL";
export const GET_CAMPAIGN_WORKING_HOUR_REQUEST =
  "GET_CAMPAIGN_WORKING_HOUR_REQUEST";
export const GET_CAMPAIGN_WORKING_HOUR_SUCCESS =
  "GET_CAMPAIGN_WORKING_HOUR_SUCCESS";
export const GET_CAMPAIGN_WORKING_HOUR_FAIL = "GET_CAMPAIGN_WORKING_HOUR_FAIL";
export const UPDATE_CAMPAIGN_REQUEST = "UPDATE_CAMPAIGN_REQUEST";
export const UPDATE_CAMPAIGN_SUCCESS = "UPDATE_CAMPAIGN_SUCCESS";
export const UPDATE_CAMPAIGN_FAIL = "UPDATE_CAMPAIGN_FAIL";
export const CHANGE_CAMPAIGN_STATUS_REQUEST = "CHANGE_CAMPAIGN_STATUS_REQUEST";
export const CHANGE_CAMPAIGN_STATUS_SUCCESS = "CHANGE_CAMPAIGN_STATUS_SUCCESS";
export const CHANGE_CAMPAIGN_STATUS_FAIL = "CHANGE_CAMPAIGN_STATUS_FAIL";
export const ADD_GROUP_TO_CAMPAIGN_REQUEST = "ADD_GROUP_TO_CAMPAIGN_REQUEST";
export const ADD_GROUP_TO_CAMPAIGN_SUCCESS = "ADD_GROUP_TO_CAMPAIGN_SUCCESS";
export const ADD_GROUP_TO_CAMPAIGN_FAIL = "ADD_GROUP_TO_CAMPAIGN_FAIL";
export const REMOVE_GROUP_FROM_CAMPAIGN_REQUEST =
  "REMOVE_GROUP_FROM_CAMPAIGN_REQUEST";
export const REMOVE_GROUP_FROM_CAMPAIGN_SUCCESS =
  "REMOVE_GROUP_FROM_CAMPAIGN_SUCCESS";
export const REMOVE_GROUP_FROM_CAMPAIGN_FAIL =
  "REMOVE_GROUP_FROM_CAMPAIGN_FAIL";

export const ARCHIVE_CAMPAIGN_REQUEST = "ARCHIVE_CAMPAIGN_REQUEST";
export const ARCHIVE_CAMPAIGN_SUCCESS = "ARCHIVE_CAMPAIGN_SUCCESS";
export const ARCHIVE_CAMPAIGN_FAIL = "ARCHIVE_CAMPAIGN_FAIL";

export const ADD_CAMPAIGN_WORKING_HOUR_REQUEST =
  "ADD_CAMPAIGN_WORKING_HOUR_REQUEST";
export const ADD_CAMPAIGN_WORKING_HOUR_SUCCESS =
  "ADD_CAMPAIGN_WORKING_HOUR_SUCCESS";
export const ADD_CAMPAIGN_WORKING_HOUR_FAIL = "ADD_CAMPAIGN_WORKING_HOUR_FAIL";
export const REMOVE_CAMPAIGN_WORKING_HOUR_REQUEST =
  "REMOVE_CAMPAIGN_WORKING_HOUR_REQUEST";
export const REMOVE_CAMPAIGN_WORKING_HOUR_SUCCESS =
  "REMOVE_CAMPAIGN_WORKING_HOUR_SUCCESS";
export const REMOVE_CAMPAIGN_WORKING_HOUR_FAIL =
  "REMOVE_CAMPAIGN_WORKING_HOUR_FAIL";
export const CAMPAIGN_SETTING_SETUP_REQUEST = "CAMPAIGN_SETTING_SETUP_REQUEST";
export const CAMPAIGN_SETTING_SETUP_SUCCESS = "CAMPAIGN_SETTING_SETUP_SUCCESS";
export const CAMPAIGN_SETTING_SETUP_FAIL = "CAMPAIGN_SETTING_SETUP_FAIL";
export const UPLOAD_CONTACT_CAMPAIGN_REQUEST =
  "UPLOAD_CONTACT_CAMPAIGN_REQUEST";
export const UPLOAD_CONTACT_CAMPAIGN_SUCCESS =
  "UPLOAD_CONTACT_CAMPAIGN_SUCCESS";
export const UPLOAD_CONTACT_CAMPAIGN_FAIL = "UPLOAD_CONTACT_CAMPAIGN_FAIL";
export const SELECT_CONTACT_FOR_CAMPAIGN_REQUEST =
  "SELECT_CONTACT_FOR_CAMPAIGN_REQUEST";
export const SELECT_CONTACT_FOR_CAMPAIGN_SUCCESS =
  "SELECT_CONTACT_FOR_CAMPAIGN_SUCCESS";
export const SELECT_CONTACT_FOR_CAMPAIGN_FAIL =
  "SELECT_CONTACT_FOR_CAMPAIGN_FAIL";
export const MIGRATE_CAMPAIGN_CONTACT_BY_FILTER_REQUEST =
  "MIGRATE_CAMPAIGN_CONTACT_BY_FILTER_REQUEST";
export const MIGRATE_CAMPAIGN_CONTACT_BY_FILTER_SUCCESS =
  "MIGRATE_CAMPAIGN_CONTACT_BY_FILTER_SUCCESS";
export const MIGRATE_CAMPAIGN_CONTACT_BY_FILTER_FAIL =
  "MIGRATE_CAMPAIGN_CONTACT_BY_FILTER_FAIL";
export const ADD_QUESTION_CAMPAIGN_REQUEST = "ADD_QUESTION_CAMPAIGN_REQUEST";
export const ADD_QUESTION_CAMPAIGN_SUCCESS = "ADD_QUESTION_CAMPAIGN_SUCCESS";
export const ADD_QUESTION_CAMPAIGN_FAIL = "ADD_QUESTION_CAMPAIGN_FAIL";

export const SUBMIT_SURVEY_REQUEST = "SUBMIT_SURVEY_REQUEST";
export const SUBMIT_SURVEY_SUCCESS = "SUBMIT_SURVEY_SUCCESS";
export const SUBMIT_SURVEY_FAIL = "SUBMIT_SURVEY_FAIL";

// Campaigns v2

export const CREATE_CAMPAIGN_WORKING_HOUR_REQUEST =
  "CREATE_CAMPAIGN_WORKING_HOUR_REQUEST";
export const CREATE_CAMPAIGN_WORKING_HOUR_SUCCESS =
  "CREATE_CAMPAIGN_WORKING_HOUR_SUCCESS";
export const CREATE_CAMPAIGN_WORKING_HOUR_FAIL =
  "CREATE_CAMPAIGN_WORKING_HOUR_FAIL";

export const CREATE_DISPOSITIONS_REQUEST = "CREATE_DISPOSITIONS_REQUEST";
export const CREATE_DISPOSITIONS_SUCCESS = "CREATE_DISPOSITIONS_SUCCESS";
export const CREATE_DISPOSITIONS_FAIL = "CREATE_DISPOSITIONS_FAIL";

export const GET_DISPOSITIONS_REQUEST = "GET_DISPOSITIONS_REQUEST";
export const GET_DISPOSITIONS_SUCCESS = "GET_DISPOSITIONS_SUCCESS";
export const GET_DISPOSITIONS_FAIL = "GET_DISPOSITIONS_FAIL";

export const UPDATE_DISPOSITIONS_REQUEST = "UPDATE_DISPOSITIONS_REQUEST";
export const UPDATE_DISPOSITIONS_SUCCESS = "UPDATE_DISPOSITIONS_SUCCESS";
export const UPDATE_DISPOSITIONS_FAIL = "UPDATE_DISPOSITIONS_FAIL";

export const UPDATE_CAMPAIGN_WORKING_HOUR_REQUEST =
  "UPDATE_CAMPAIGN_WORKING_HOUR_REQUEST";
export const UPDATE_CAMPAIGN_WORKING_HOUR_SUCCESS =
  "UPDATE_CAMPAIGN_WORKING_HOUR_SUCCESS";
export const UPDATE_CAMPAIGN_WORKING_HOUR_FAIL =
  "UPDATE_CAMPAIGN_WORKING_HOUR_FAIL";

export const UPDATE_CAMPAIGN_WORKING_HOUR_NAME_REQUEST =
  "UPDATE_CAMPAIGN_WORKING_HOUR_NAME_REQUEST";
export const UPDATE_CAMPAIGN_WORKING_HOUR_NAME_SUCCESS =
  "UPDATE_CAMPAIGN_WORKING_HOUR_NAME_SUCCESS";
export const UPDATE_CAMPAIGN_WORKING_HOUR_NAME_FAIL =
  "UPDATE_CAMPAIGN_WORKING_HOUR_NAME_FAIL";

export const GET_CAMPAIGN_WORKING_HOUR_V2_REQUEST =
  "GET_CAMPAIGN_WORKING_HOUR_V2_REQUEST";
export const GET_CAMPAIGN_WORKING_HOUR_V2_SUCCESS =
  "GET_CAMPAIGN_WORKING_HOUR_V2_SUCCESS";
export const GET_CAMPAIGN_WORKING_HOUR_V2_FAIL =
  "GET_CAMPAIGN_WORKING_HOUR_V2_FAIL";

export const CREATE_CONTACT_DATA_SETS_REQUEST =
  "CREATE_CONTACT_DATA_SETS_REQUEST";
export const CREATE_CONTACT_DATA_SETS_SUCCESS =
  "CREATE_CONTACT_DATA_SETS_SUCCESS";
export const CREATE_CONTACT_DATA_SETS_FAIL = "CREATE_CONTACT_DATA_SETS_FAIL";

export const ARCHIVE_CONTACT_DATA_SETS_REQUEST =
  "ARCHIVE_CONTACT_DATA_SETS_REQUEST";
export const ARCHIVE_CONTACT_DATA_SETS_SUCCESS =
  "ARCHIVE_CONTACT_DATA_SETS_SUCCESS";
export const ARCHIVE_CONTACT_DATA_SETS_FAIL = "ARCHIVE_CONTACT_DATA_SETS_FAIL";

export const GET_CONTACT_DATA_SETS_REQUEST = "GET_CONTACT_DATA_SETS_REQUEST";
export const GET_CONTACT_DATA_SETS_SUCCESS = "GET_CONTACT_DATA_SETS_SUCCESS";
export const GET_CONTACT_DATA_SETS_FAIL = "GET_CONTACT_DATA_SETS_FAIL";

export const UPDATE_CONTACT_DATA_SETS_REQUEST =
  "UPDATE_CONTACT_DATA_SETS_REQUEST";
export const UPDATE_CONTACT_DATA_SETS_SUCCESS =
  "UPDATE_CONTACT_DATA_SETS_SUCCESS";
export const UPDATE_CONTACT_DATA_SETS_FAIL = "UPDATE_CONTACT_DATA_SETS_FAIL";

export const GET_CONTACT_DATA_SETS_HEADERS_REQUEST =
  "GET_CONTACT_DATA_SETS_HEADERS_REQUEST";
export const GET_CONTACT_DATA_SETS_HEADERS_SUCCESS =
  "GET_CONTACT_DATA_SETS_HEADERS_SUCCESS";
export const GET_CONTACT_DATA_SETS_HEADERS_FAIL =
  "GET_CONTACT_DATA_SETS_HEADERS_FAIL";

export const GET_CONTACT_DATA_SETS_CONTACTS_REQUEST =
  "GET_CONTACT_DATA_SETS_CONTACTS_REQUEST";
export const GET_CONTACT_DATA_SETS_CONTACTS_SUCCESS =
  "GET_CONTACT_DATA_SETS_CONTACTS_SUCCESS";
export const GET_CONTACT_DATA_SETS_CONTACTS_FAIL =
  "GET_CONTACT_DATA_SETS_CONTACTS_FAIL";

export const GET_DIALER_TYPES_REQUEST = "GET_DIALER_TYPES_REQUEST";
export const GET_DIALER_TYPES_SUCCESS = "GET_DIALER_TYPES_SUCCESS";
export const GET_DIALER_TYPES_FAIL = "GET_DIALER_TYPES_FAIL";

export const GET_ROUTING_TYPES_REQUEST = "GET_ROUTING_TYPES_REQUEST";
export const GET_ROUTING_TYPES_SUCCESS = "GET_ROUTING_TYPES_SUCCESS";
export const GET_ROUTING_TYPES_FAIL = "GET_ROUTING_TYPES_FAIL";

export const GET_CAMPAIGN_DETAIL_REQUEST = "GET_CAMPAIGN_DETAIL_REQUEST";
export const GET_CAMPAIGN_DETAIL_SUCCESS = "GET_CAMPAIGN_DETAIL_SUCCESS";
export const GET_CAMPAIGN_DETAIL_FAIL = "GET_CAMPAIGN_DETAIL_FAIL";

export const GET_DIALER_SETTINGS_REQUEST = "GET_DIALER_SETTINGS_REQUEST";
export const GET_DIALER_SETTINGS_SUCCESS = "GET_DIALER_SETTINGS_SUCCESS";
export const GET_DIALER_SETTINGS_FAIL = "GET_DIALER_SETTINGS_FAIL";

export const UPDATE_DIALER_SETTINGS_REQUEST = "UPDATE_DIALER_SETTINGS_REQUEST";
export const UPDATE_DIALER_SETTINGS_SUCCESS = "UPDATE_DIALER_SETTINGS_SUCCESS";
export const UPDATE_DIALER_SETTINGS_FAIL = "UPDATE_DIALER_SETTINGS_FAIL";

export const ADD_CAMPAIGN_DATA_SET_REQUEST = "ADD_CAMPAIGN_DATA_SET_REQUEST";
export const ADD_CAMPAIGN_DATA_SET_SUCCESS = "ADD_CAMPAIGN_DATA_SET_SUCCESS";
export const ADD_CAMPAIGN_DATA_SET_FAIL = "ADD_CAMPAIGN_DATA_SET_FAIL";

export const GET_CAMPAIGN_CONTACTS_DATA_REQUEST =
  "GET_CAMPAIGN_CONTACTS_DATA_REQUEST";
export const GET_CAMPAIGN_CONTACTS_DATA_SUCCESS =
  "GET_CAMPAIGN_CONTACTS_DATA_SUCCESS";
export const GET_CAMPAIGN_CONTACTS_DATA_FAIL =
  "GET_CAMPAIGN_CONTACTS_DATA_FAIL";

// submit desposition
export const SUBMIT_DESPOSITION_REQUEST = "SUBMIT_DESPOSITION_REQUEST";
export const SUBMIT_DESPOSITION_SUCCESS = "SUBMIT_DESPOSITION_SUCCESS";
export const SUBMIT_DESPOSITION_FAIL = "SUBMIT_DESPOSITION_FAIL";

export const REMOVE_NONCONTACTED_REQUEST = "REMOVE_NONCONTACTED_REQUEST";
export const REMOVE_NONCONTACTED_SUCCESS = "REMOVE_NONCONTACTED_SUCCESS";
export const REMOVE_NONCONTACTED_FAIL = "REMOVE_NONCONTACTED_FAIL";

//get campaign call report
export const GET_CAMPAIGN_CALL_REPORT_REQUEST =
  "GET_CAMPAIGN_CALL_REPORT_REQUEST";
export const GET_CAMPAIGN_CALL_REPORT_SUCCESS =
  "GET_CAMPAIGN_CALL_REPORT_SUCCESS";
export const GET_CAMPAIGN_CALL_REPORT_FAIL = "GET_CAMPAIGN_CALL_REPORT_FAIL";

export const GET_CAMPAIGN_REPORTS_V2_REQUEST =
  "GET_CAMPAIGN_REPORTS_V2_REQUEST";
export const GET_CAMPAIGN_REPORTS_V2_SUCCESS =
  "GET_CAMPAIGN_REPORTS_V2_SUCCESS";
export const GET_CAMPAIGN_REPORTS_V2_FAIL = "GET_CAMPAIGN_REPORTS_V2_FAIL";

export const GET_CAMPAIGN_DISPOSITIONS_REQUEST =
  "GET_CAMPAIGN_DISPOSITION_REQUEST";
export const GET_CAMPAIGN_DISPOSITIONS_SUCCESS =
  "GET_CAMPAIGN_DISPOSITION_SUCCESS";
export const GET_CAMPAIGN_DISPOSITIONS_FAIL = "GET_CAMPAIGN_DISPOSITION_FAIL";

export const RECYCLE_CONTACTS_REQUEST = "RECYCLE_CONTACTS_REQUEST";
export const RECYCLE_CONTACTS_SUCCESS = "RECYCLE_CONTACTS_SUCCESS";
export const RECYCLE_CONTACTS_FAIL = "RECYCLE_CONTACTS_FAIL";

const state = {
  loadingCampaign: false,
  successCampaign: "",
  errorCampaign: "",
  campaign: [],
  selectCampaign: [],
  senderId: [],
  selectSenderId: [],
  queueFromCampaign: [],
  selectQueueFromCampaign: [],
  campaignContact: [],
  selectCampaignContact: [],
  campaignWorkingHour: [],
  campaignWorkingHourV2: [],
  selectCampaignWorkingHour: [],
  campaignType: [],
  selectCampaignType: [],
  campaignReportTable: [],
  surveyReportTable: [],
  surveyReportCumulative: [],
  campaignSurveyId: "",
  dispositions: [],
  contactDataSets: [],
  selectContactDataSets: [],
  contactDataSetHeader: [],
  contactDataSetFormat: [[], []],
  dataSetContacts: [],
  selectCampaignWorkingHourV2: [],
  dialerTypes: [],
  routingTypes: [],
  campaignDetail: [],
  dialerSettings: [],
  campaignQueues: [],
  dropActions: [],
  dialerDispositions: [],
  dialerDids: [],
  campaignContactsData: [],
  //campaign call report
  campaignCallReport: [],
  campaignReportsV2: [],
  campaigDespositions: [],
  campaignDispositions: [],
};

const getters = {
  loadingCampaign: (state) => state.loadingCampaign,
  successCampaign: (state) => state.successCampaign,
  errorCampaign: (state) => state.errorCampaign,
  campaign: (state) => state.campaign,
  selectCampaign: (state) => state.selectCampaign,
  senderId: (state) => state.senderId,
  selectSenderId: (state) => state.selectSenderId,
  queueFromCampaign: (state) => state.queueFromCampaign,
  selectQueueFromCampaign: (state) => state.selectQueueFromCampaign,
  campaignContact: (state) => state.campaignContact,
  selectCampaignContact: (state) => state.selectCampaignContact,
  campaignWorkingHour: (state) => state.campaignWorkingHour,

  campaignWorkingHourV2: (state) => state.campaignWorkingHourV2,
  selectCampaignWorkingHourV2: (state) => state.selectCampaignWorkingHourV2,
  campaignType: (state) => state.campaignType,
  selectCampaignType: (state) => state.selectCampaignType,
  campaignReportTable: (state) => state.campaignReportTable,
  surveyReportTable: (state) => state.surveyReportTable,
  surveyReportCumulative: (state) => state.surveyReportCumulative,
  campaignSurveyId: (state) => state.campaignSurveyId,
  dispositions: (state) => state.dispositions,
  contactDataSets: (state) => state.contactDataSets,
  selectContactDataSets: (state) => state.selectContactDataSets,
  contactDataSetHeader: (state) => state.contactDataSetHeader,
  contactDataSetFormat: (state) => state.contactDataSetFormat,
  dataSetContacts: (state) => state.dataSetContacts,
  dialerTypes: (state) => state.dialerTypes,
  routingTypes: (state) => state.routingTypes,
  campaignDetail: (state) => state.campaignDetail,
  dialerSettings: (state) => state.dialerSettings,
  campaignQueues: (state) => state.campaignQueues,
  dropActions: (state) => state.dropActions,
  dialerDispositions: (state) => state.dialerDispositions,
  dialerDids: (state) => state.dialerDids,
  campaignContactsData: (state) => state.campaignContactsData,
  //campaign call report
  campaignCallReport: (state) => state.campaignCallReport,
  campaignReportsV2: (state) => state.campaignReportsV2,
  campaigDespositions: (state) => state.campaigDespositions,
  campaignDispositions: (state) => state.campaignDispositions,
};

const mutations = {
  /* 
    CREATE CAMPAIGN
  */
  [CREATE_CAMPAIGN_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [CREATE_CAMPAIGN_SUCCESS](state, payload) {
    state.loadingCampaign = false;
    state.successCampaign = payload.message;
  },
  [CREATE_CAMPAIGN_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },

  /* 
    CREATE DISPOSITIONS
  */
  [CREATE_DISPOSITIONS_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [CREATE_DISPOSITIONS_SUCCESS](state, payload) {
    state.loadingCampaign = false;
    state.successCampaign = payload.message;
  },
  [CREATE_DISPOSITIONS_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },

  /* 
    get DISPOSITIONS
  */
  [GET_DISPOSITIONS_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [GET_DISPOSITIONS_SUCCESS](state, payload) {
    state.loadingCampaign = false;
    state.successCampaign = "Successfully fetched";
    state.dispositions = payload;
  },
  [GET_DISPOSITIONS_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },

  /* 
    update DISPOSITIONS
  */
  [UPDATE_DISPOSITIONS_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [UPDATE_DISPOSITIONS_SUCCESS](state, payload) {
    state.loadingCampaign = false;
    state.successCampaign = payload;
  },
  [UPDATE_DISPOSITIONS_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },

  /* 
    CREATE CONTACT DATA SETS
  */
  [CREATE_CONTACT_DATA_SETS_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [CREATE_CONTACT_DATA_SETS_SUCCESS](state, payload) {
    state.loadingCampaign = false;
    state.successCampaign = payload.message;
  },
  [CREATE_CONTACT_DATA_SETS_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },

  /*
  archive contact data sets
  */
  [ARCHIVE_CONTACT_DATA_SETS_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [ARCHIVE_CONTACT_DATA_SETS_SUCCESS](state, payload) {
    state.loadingCampaign = false;
    state.successCampaign = payload.message;
  },
  [ARCHIVE_CONTACT_DATA_SETS_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },

  /* 
    GET CONTACT DATA SETS
  */
  [GET_CONTACT_DATA_SETS_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [GET_CONTACT_DATA_SETS_SUCCESS](state, payload) {
    state.loadingCampaign = false;
    state.successCampaign = "Successfully Fetched";
    state.contactDataSets = payload;
    state.selectContactDataSets = payload.map((campaign) => {
      return { label: campaign.name, value: campaign.id };
    });
  },
  [GET_CONTACT_DATA_SETS_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },

  /* 
    GET COAMPAIGN CONTACTS DATA
  */
  [GET_CAMPAIGN_CONTACTS_DATA_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [GET_CAMPAIGN_CONTACTS_DATA_SUCCESS](state, payload) {
    state.loadingCampaign = false;
    state.successCampaign = "Successfully Fetched";
    state.campaignContactsData = payload;
  },
  [GET_CAMPAIGN_CONTACTS_DATA_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },

  /* 
    GET COAMPAIGN CONTACTS DATA
  */
  [GET_CONTACT_DATA_SETS_CONTACTS_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [GET_CONTACT_DATA_SETS_CONTACTS_SUCCESS](state, payload) {
    state.loadingCampaign = false;
    state.successCampaign = "Successfully Fetched";
    state.dataSetContacts = payload;
  },
  [GET_CONTACT_DATA_SETS_CONTACTS_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },

  /* 
    GET DIALER TYPES
  */
  [GET_DIALER_TYPES_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [GET_DIALER_TYPES_SUCCESS](state, payload) {
    state.loadingCampaign = false;
    state.successCampaign = "Successfully Fetched";
    state.dialerTypes = payload[0];
    console.log("these are the dialer types", payload[0]);
    state.dialerTypes = payload[0].map((campaign) => {
      return { label: campaign, value: campaign };
    });
  },
  [GET_DIALER_TYPES_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },

  /* 
    GET DIALER TYPES
  */
  [UPDATE_DIALER_SETTINGS_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [UPDATE_DIALER_SETTINGS_SUCCESS](state) {
    state.loadingCampaign = false;
    state.successCampaign = "Successfully Fetched";
  },
  [UPDATE_DIALER_SETTINGS_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },

  /* 
    ADD CMPAIGN DATA SETS
  */
  [ADD_CAMPAIGN_DATA_SET_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [ADD_CAMPAIGN_DATA_SET_SUCCESS](state, payload) {
    state.loadingCampaign = false;
    state.successCampaign = payload.message;
  },
  [ADD_CAMPAIGN_DATA_SET_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },

  /* 
    GET DIALER TYPES
  */
  [GET_ROUTING_TYPES_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [GET_ROUTING_TYPES_SUCCESS](state, payload) {
    console.log("I am getting here routing type");
    state.loadingCampaign = false;
    state.successCampaign = "Successfully Fetched";
    state.routingTypes = payload[0];
    state.routingTypes = payload[0].map((campaign) => {
      return { label: campaign, value: campaign };
    });
  },
  [GET_ROUTING_TYPES_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },

  /* 
    GET CAMPAIGN DETAIL
  */
  [GET_CAMPAIGN_DETAIL_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [GET_CAMPAIGN_DETAIL_SUCCESS](state, payload) {
    console.log("I am getting here routing type");
    state.loadingCampaign = false;
    state.successCampaign = "Successfully Fetched";
    state.campaignDetail = payload;
  },
  [GET_CAMPAIGN_DETAIL_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },

  /* 
    GET DIALER SETTINGS
  */
  [GET_DIALER_SETTINGS_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [GET_DIALER_SETTINGS_SUCCESS](state, payload) {
    console.log("I am getting here routing type");
    state.loadingCampaign = false;
    state.successCampaign = "Successfully Fetched";
    state.dialerSettings = payload;
    state.campaignQueues = payload.queues.map((campaign) => {
      return { label: campaign.name, value: campaign.id };
    });
    state.dropActions = payload.dropActions.map((campaign) => {
      return { label: campaign, value: campaign };
    });
    state.dialerDispositions = payload.despositions.map((campaign) => {
      return { label: campaign.desposition, value: campaign.id };
    });
    state.dialerDids = payload.dids.map((campaign) => {
      return { label: campaign.did, value: campaign.id };
    });
  },
  [GET_DIALER_SETTINGS_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },

  /* 
    update CONTACT DATA SETS
  */
  [UPDATE_CONTACT_DATA_SETS_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [UPDATE_CONTACT_DATA_SETS_SUCCESS](state, payload) {
    state.loadingCampaign = false;
    state.successCampaign = "Successfully Updated";
    console.log(payload);
  },
  [UPDATE_CONTACT_DATA_SETS_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },

  /* 
    RECYCLE CONTACTS
  */
  [RECYCLE_CONTACTS_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [RECYCLE_CONTACTS_SUCCESS](state, payload) {
    state.loadingCampaign = false;
    state.successCampaign = "Successfully Updated";
    console.log(payload);
  },
  [RECYCLE_CONTACTS_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },

  /* 
    GET CONTACT DATA SETS HEADERS
  */
  [GET_CONTACT_DATA_SETS_HEADERS_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [GET_CONTACT_DATA_SETS_HEADERS_SUCCESS](state, payload) {
    state.loadingCampaign = false;
    state.successCampaign = "Successfully Fetched";
    state.contactDataSetHeader = payload;
    console.log("this is the header array", state.contactDataSetHeader);

    var i = 0;
    for (i = 0; i < 2; i++) {
      payload.forEach((element) => {
        state.contactDataSetFormat[i][element] = element;
      });
    }
    console.log(
      "this is the formated header array",
      state.contactDataSetFormat
    );
  },
  [GET_CONTACT_DATA_SETS_HEADERS_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },
  /* 
   GET CAMPAIGN REPORT
  */
  [GET_CAMPAIGN_REPORT_TABLE_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [GET_CAMPAIGN_REPORT_TABLE_SUCCESS](state, payload) {
    state.loadingCampaign = false;
    state.campaignReportTable = payload;
    console.log("get campaign reports table", payload);
  },
  [GET_CAMPAIGN_REPORT_TABLE_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },

  /* 
   GET SURVEYREPORT
  */
  [GET_SURVEY_REPORT_TABLE_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [GET_SURVEY_REPORT_TABLE_SUCCESS](state, payload) {
    state.loadingCampaign = false;
    state.surveyReportTable = payload;
    console.log("get campaign reports table", payload);
  },
  [GET_SURVEY_REPORT_TABLE_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },

  /* 
   GET SURVEYREPORT CUMULATIVE
  */
  [GET_SURVEY_REPORT_CUMULATIVE_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [GET_SURVEY_REPORT_CUMULATIVE_SUCCESS](state, payload) {
    state.loadingCampaign = false;
    state.surveyReportCumulative = payload;
    console.log("get campaign reports Cumulative", payload);
  },
  [GET_SURVEY_REPORT_CUMULATIVE_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },

  /*SUBMIT SURVEY */

  [SUBMIT_SURVEY_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [SUBMIT_SURVEY_SUCCESS](state, payload) {
    state.loadingCampaign = false;
    state.successCampaign = payload.message;
  },
  [SUBMIT_SURVEY_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },

  [CREATE_CAMPAIGN_SURVEY_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [CREATE_CAMPAIGN_SURVEY_SUCCESS](state, payload) {
    state.loadingCampaign = false;
    state.successCampaign = payload.message;
  },
  [CREATE_CAMPAIGN_SURVEY_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },
  /* 
    GET CAMPAIGN TYPE
  */
  [GET_CAMPAIGN_TYPE_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [GET_CAMPAIGN_TYPE_SUCCESS](state, payload) {
    state.loadingCampaign = false;
    state.campaignType = payload;
    state.selectCampaignType = payload.map((campaign) => {
      return { label: campaign.name, value: campaign.name };
    });
  },
  [GET_CAMPAIGN_TYPE_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },
  /* 
    GET CAMPAIGN
  */
  [GET_CAMPAIGN_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [GET_CAMPAIGN_SUCCESS](state, payload) {
    state.loadingCampaign = false;
    state.campaign = payload;
    state.selectCampaign = payload.map((campaign) => {
      return { label: campaign.name, value: campaign.id };
    });
  },
  [GET_CAMPAIGN_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },

  /* 
    GET SENDER ID
  */
  [GET_SENDER_ID_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [GET_SENDER_ID_SUCCESS](state, payload) {
    state.loadingCampaign = false;
    state.senderId = payload;
    state.selectSenderId = payload.map((sender) => {
      return { label: sender.name, value: sender.id };
    });
  },
  [GET_SENDER_ID_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },

  /* 
    GET QUEUE FROM CAMPAIGN
  */
  [GET_QUEUE_FROM_CAMPAIGN_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [GET_QUEUE_FROM_CAMPAIGN_SUCCESS](state, payload) {
    state.loadingCampaign = false;
    state.queueFromCampaign = payload;
    state.selectQueueFromCampaign = payload.map((queue) => {
      return { label: queue.name, value: queue.id };
    });
  },
  [GET_QUEUE_FROM_CAMPAIGN_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },

  /* 
    GET CAMPAIGN CONTACT
  */
  [GET_CAMPAIGN_CONTACT_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [GET_CAMPAIGN_CONTACT_SUCCESS](state, payload) {
    state.loadingCampaign = false;
    state.campaignContact = payload;
    state.selectCampaignContact = payload.map((contact) => {
      return { label: contact.name, value: contact.id };
    });
  },
  [GET_CAMPAIGN_CONTACT_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },
  /* 
    GET CAMPAIGN WORKING HOUR
  */
  [GET_CAMPAIGN_WORKING_HOUR_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [GET_CAMPAIGN_WORKING_HOUR_SUCCESS](state, payload) {
    state.loadingCampaign = false;
    state.campaignWorkingHour = payload;
    state.selectCampaignWorkingHour = payload.map((campaign) => {
      return { label: campaign.date, value: campaign.id };
    });
  },
  [GET_CAMPAIGN_WORKING_HOUR_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },

  /* 
    GET CAMPAIGN WORKING HOUR
  */
  [GET_CAMPAIGN_WORKING_HOUR_V2_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [GET_CAMPAIGN_WORKING_HOUR_V2_SUCCESS](state, payload) {
    state.loadingCampaign = false;
    state.campaignWorkingHourV2 = payload;
    console.log("campaign working hour response", state.campaignWorkingHourV2);
    state.selectCampaignWorkingHourV2 = payload.map((campaign) => {
      return { label: campaign.name, value: campaign.id };
    });
  },
  [GET_CAMPAIGN_WORKING_HOUR_V2_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },
  /* 
    UPDATE CAMPAIGN
  */
  [UPDATE_CAMPAIGN_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [UPDATE_CAMPAIGN_SUCCESS](state, payload) {
    state.loadingCampaign = false;
    state.successCampaign = payload.message;
  },
  [UPDATE_CAMPAIGN_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },
  /* 
    CHANGE CAMPAIGN STATUS
  */
  [CHANGE_CAMPAIGN_STATUS_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [CHANGE_CAMPAIGN_STATUS_SUCCESS](state, payload) {
    state.loadingCampaign = false;
    state.successCampaign = payload.message;
  },
  [CHANGE_CAMPAIGN_STATUS_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },
  /* 
      ADD GROUP TO CAMPAIGN
    */
  [ADD_GROUP_TO_CAMPAIGN_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [ADD_GROUP_TO_CAMPAIGN_SUCCESS](state, payload) {
    state.loadingCampaign = false;
    state.successCampaign = payload.message;
  },
  [ADD_GROUP_TO_CAMPAIGN_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },
  /* 
          REMOVE GROUP FROM CAMPAIGN
        */
  [REMOVE_GROUP_FROM_CAMPAIGN_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [REMOVE_GROUP_FROM_CAMPAIGN_SUCCESS](state, payload) {
    state.loadingCampaign = false;
    state.successCampaign = payload;
  },
  [REMOVE_GROUP_FROM_CAMPAIGN_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },
  /*
ARCHIVE CAMPAIGN
 */
  [ARCHIVE_CAMPAIGN_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [ARCHIVE_CAMPAIGN_SUCCESS](state, payload) {
    state.loadingCampaign = false;
    state.successCampaign = payload;
  },
  [ARCHIVE_CAMPAIGN_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },

  /* 
      ADD CAMPAIGN WORKING HOUR
    */
  [ADD_CAMPAIGN_WORKING_HOUR_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [ADD_CAMPAIGN_WORKING_HOUR_SUCCESS](state, payload) {
    state.loadingCampaign = false;
    state.successCampaign = payload.message;
  },
  [ADD_CAMPAIGN_WORKING_HOUR_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },
  /* 
      CREATE CAMPAIGN WORKING HOUR
    */
  [CREATE_CAMPAIGN_WORKING_HOUR_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [CREATE_CAMPAIGN_WORKING_HOUR_SUCCESS](state, payload) {
    state.loadingCampaign = false;
    state.successCampaign = payload[0];
  },
  [CREATE_CAMPAIGN_WORKING_HOUR_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },

  /* 
      UPDATE CAMPAIGN WORKING HOUR
    */
  [UPDATE_CAMPAIGN_WORKING_HOUR_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [UPDATE_CAMPAIGN_WORKING_HOUR_SUCCESS](state, payload) {
    state.loadingCampaign = false;
    state.successCampaign = payload.message;
  },
  [UPDATE_CAMPAIGN_WORKING_HOUR_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },

  /* 
      UPDATE CAMPAIGN WORKING HOUR
    */
  [UPDATE_CAMPAIGN_WORKING_HOUR_NAME_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [UPDATE_CAMPAIGN_WORKING_HOUR_NAME_SUCCESS](state, payload) {
    state.loadingCampaign = false;
    state.successCampaign = payload.message;
  },
  [UPDATE_CAMPAIGN_WORKING_HOUR_NAME_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },

  /* 
    REMOVE CAMPAIGN WORKING HOUR
  */
  [REMOVE_CAMPAIGN_WORKING_HOUR_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [REMOVE_CAMPAIGN_WORKING_HOUR_SUCCESS](state, payload) {
    state.loadingCampaign = false;
    state.successCampaign = payload.message;
  },
  [REMOVE_CAMPAIGN_WORKING_HOUR_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },
  /* 
        CAMPAIGN SETTING SETUP
      */
  [CAMPAIGN_SETTING_SETUP_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [CAMPAIGN_SETTING_SETUP_SUCCESS](state, payload) {
    state.loadingCampaign = false;
    state.successCampaign = payload.message;
  },
  [CAMPAIGN_SETTING_SETUP_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },
  /* 
            UPLOAD CONTACT CAMPAIGN
          */
  [UPLOAD_CONTACT_CAMPAIGN_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [UPLOAD_CONTACT_CAMPAIGN_SUCCESS](state, payload) {
    state.loadingCampaign = false;
    state.successCampaign = payload;
  },
  [UPLOAD_CONTACT_CAMPAIGN_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },
  /* 
        SELECT CONTACT FOR CAMPAIGN
      */
  [SELECT_CONTACT_FOR_CAMPAIGN_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [SELECT_CONTACT_FOR_CAMPAIGN_SUCCESS](state, payload) {
    state.loadingCampaign = false;
    state.successCampaign = payload.message;
  },
  [SELECT_CONTACT_FOR_CAMPAIGN_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },
  /* 
        MIGRATE CAMPAIGN CONTACT BY FILTER
      */
  [MIGRATE_CAMPAIGN_CONTACT_BY_FILTER_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [MIGRATE_CAMPAIGN_CONTACT_BY_FILTER_SUCCESS](state, payload) {
    state.loadingCampaign = false;
    state.successCampaign = payload.message;
  },
  [MIGRATE_CAMPAIGN_CONTACT_BY_FILTER_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },
  /* 
        ADD QUESTION CAMPAIGN
      */
  [ADD_QUESTION_CAMPAIGN_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [ADD_QUESTION_CAMPAIGN_SUCCESS](state, payload) {
    state.loadingCampaign = false;
    state.successCampaign = payload.message;
  },
  [ADD_QUESTION_CAMPAIGN_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },

  //submit desposition
  [SUBMIT_DESPOSITION_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [SUBMIT_DESPOSITION_SUCCESS](state, payload) {
    state.loadingCampaign = false;
    state.successCampaign = payload.message;
  },
  [SUBMIT_DESPOSITION_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },
  //campaign call report
  [GET_CAMPAIGN_CALL_REPORT_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [GET_CAMPAIGN_CALL_REPORT_SUCCESS](state, payload) {
    state.loadingCampaign = false;
    state.campaignCallReport = payload;
  },
  [GET_CAMPAIGN_CALL_REPORT_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },
  [REMOVE_NONCONTACTED_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [REMOVE_NONCONTACTED_SUCCESS](state, payload) {
    state.loadingCampaign = false;
    state.successCampaign = payload.message;
  },
  [REMOVE_NONCONTACTED_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },

  [GET_CAMPAIGN_REPORTS_V2_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [GET_CAMPAIGN_REPORTS_V2_SUCCESS](state, payload) {
    state.loadingCampaign = false;
    state.campaignReportsV2 = payload;
  },
  [GET_CAMPAIGN_REPORTS_V2_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },

  [GET_CAMPAIGN_DISPOSITIONS_REQUEST](state) {
    state.loadingCampaign = true;
    state.errorCampaign = "";
  },
  [GET_CAMPAIGN_DISPOSITIONS_SUCCESS](state, payload) {
    state.loadingCampaign = false;
    state.campaignDispositions = payload;
    state.campaignDispositions = payload.map((campaign) => {
      return { label: campaign.desposition, value: campaign.id };
    });
  },
  [GET_CAMPAIGN_DISPOSITIONS_FAIL](state, errorCampaign) {
    state.loadingCampaign = false;
    state.errorCampaign = errorCampaign;
  },
};

const actions = {
  /* 
    SUBMIT SURVEY
  */
  async submitSurvey({ commit }, payload) {
    commit(SUBMIT_SURVEY_REQUEST);

    var config = {
      method: "post",
      url: `${baseUrl}campaign/survey_submit`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("create campaign config", config);

    axios(config)
      .then(function (response) {
        console.log("create campaign response", response.data);
        commit(SUBMIT_SURVEY_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(SUBMIT_SURVEY_FAIL, error.response.data);
        }
      });
  },
  /* 
    CREATE DISPOSITION
  */
  async createdDisposition({ commit, dispatch }, payload) {
    commit(CREATE_DISPOSITIONS_REQUEST);

    var config = {
      method: "post",
      url: `${baseUrl}desposition/create_desposition`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("create campaign config", config);

    axios(config)
      .then(function (response) {
        console.log("create campaign response", response.data);
        commit(CREATE_DISPOSITIONS_SUCCESS, response.data);
        dispatch("getDisposition");
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(CREATE_DISPOSITIONS_FAIL, error.response.data);
          dispatch("getDisposition");
        }
      });
  },

  /* 
   ADD CAMPAIGN DATA SETS
  */
  async addCampaignDataSets({ commit, dispatch }, payload) {
    commit(ADD_CAMPAIGN_DATA_SET_REQUEST);

    var config = {
      method: "post",
      url: `${baseUrl}campaign/select_contact_for_campaign`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("create campaign config", config);

    axios(config)
      .then(function (response) {
        console.log("create campaign response", response.data);
        commit(ADD_CAMPAIGN_DATA_SET_SUCCESS, response.data);
        dispatch("getDisposition");
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(ADD_CAMPAIGN_DATA_SET_FAIL, error.response.data);
          dispatch("getDisposition");
        }
      });
  },

  /* 
    GET DISPOSITION
  */
  async getDisposition({ commit }, payload) {
    commit(GET_DISPOSITIONS_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}desposition/get_desposition`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("create campaign config", config);

    axios(config)
      .then(function (response) {
        console.log("create campaign response", response.data);
        commit(GET_DISPOSITIONS_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(GET_DISPOSITIONS_FAIL, error.response.data);
        }
      });
  },

  /* 
    GET DIALER TYPES
  */
  async getDialerTypes({ commit }, payload) {
    commit(GET_DIALER_TYPES_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}campaign/get_dialer_type`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("create campaign config", config);

    axios(config)
      .then(function (response) {
        console.log("create campaign response", response.data);
        commit(GET_DIALER_TYPES_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(GET_DIALER_TYPES_FAIL, error.response.data);
        }
      });
  },
  /* 
    GET ROUTING TYPES
  */
  async getRoutingTypes({ commit }, payload) {
    commit(GET_ROUTING_TYPES_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}campaign/get_routing_type`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("create campaign config", config);

    axios(config)
      .then(function (response) {
        console.log("create campaign response", response.data);
        commit(GET_ROUTING_TYPES_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(GET_ROUTING_TYPES_FAIL, error.response.data);
        }
      });
  },

  /* 
    GET CAMPAIGN DETAIL
  */
  async getCampaignDetail({ commit }, payload) {
    commit(GET_CAMPAIGN_DETAIL_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}campaign/get_campaign_detail?campaign_id=${payload.id}`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("create campaign config", config);

    axios(config)
      .then(function (response) {
        console.log("create campaign response", response.data);
        commit(GET_CAMPAIGN_DETAIL_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(GET_CAMPAIGN_DETAIL_FAIL, error.response.data);
        }
      });
  },

  /* 
    GET DIALER SETTTINGS
  */
  async getDialerSettings({ commit }, payload) {
    commit(GET_DIALER_SETTINGS_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}campaign/get_dialer_setting_items`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("create campaign config", config);

    axios(config)
      .then(function (response) {
        console.log("create campaign response", response.data);
        commit(GET_DIALER_SETTINGS_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(GET_DIALER_SETTINGS_FAIL, error.response.data);
        }
      });
  },

  /* 
    UPDATE DIALER SETTTINGS
  */
  async updateDialerSettings({ commit }, payload) {
    commit(UPDATE_DIALER_SETTINGS_REQUEST);

    var config = {
      method: "PUT",
      url: `${baseUrl}campaign/update_campaign_settings`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("create campaign config", config);

    axios(config)
      .then(function (response) {
        console.log("create campaign response", response.data);
        commit(UPDATE_DIALER_SETTINGS_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(UPDATE_DIALER_SETTINGS_FAIL, error.response.data);
        }
      });
  },

  /* 
    UPDATE DIALER SETTTINGS
  */
  async recycleContactsSystem({ commit }, payload) {
    commit(RECYCLE_CONTACTS_REQUEST);

    var config = {
      method: "PUT",
      url: `${baseUrl}campaign/recallCampaignByStatus`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("create campaign config", config);

    axios(config)
      .then(function (response) {
        console.log("create campaign response", response.data);
        commit(RECYCLE_CONTACTS_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(RECYCLE_CONTACTS_FAIL, error.response.data);
        }
      });
  },

  /* 
    UPDATE DIALER SETTTINGS
  */
  async recycleContactsDisposition({ commit }, payload) {
    commit(RECYCLE_CONTACTS_REQUEST);

    var config = {
      method: "PUT",
      url: `${baseUrl}campaign/recallCampaignByDesposition`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("create campaign config", config);

    axios(config)
      .then(function (response) {
        console.log("create campaign response", response.data);
        commit(RECYCLE_CONTACTS_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(RECYCLE_CONTACTS_FAIL, error.response.data);
        }
      });
  },

  /* 
    CREATE DISPOSITION
  */
  async updateDisposition({ commit, dispatch }, payload) {
    commit(CREATE_DISPOSITIONS_REQUEST);

    var config = {
      method: "put",
      url: `${baseUrl}desposition/update_desposition`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("create campaign config", config);

    axios(config)
      .then(function (response) {
        console.log("create campaign response", response.data);
        commit(CREATE_DISPOSITIONS_SUCCESS, response.data);
        dispatch("getDisposition");
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(CREATE_DISPOSITIONS_FAIL, error.response.data);
          dispatch("getDisposition");
        }
      });
  },

  /* 
    CREATE DATA SETS
  */
  async createContactDataSet({ commit, dispatch }, payload) {
    commit(CREATE_CONTACT_DATA_SETS_REQUEST);

    var config = {
      method: "post",
      url: `${baseUrl}dataset/create_dataset`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("create campaign config", config);

    axios(config)
      .then(function (response) {
        console.log("create campaign response", response.data);
        commit(CREATE_CONTACT_DATA_SETS_SUCCESS, response.data);
        dispatch("getContactDataSets");
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(CREATE_CONTACT_DATA_SETS_FAIL, error.response.data);
          dispatch("getContactDataSets");
        }
      });
  },

  /*
    archive datasets
  */
  async archiveContactDataSet({ commit, dispatch }, payload) {
    commit(ARCHIVE_CONTACT_DATA_SETS_REQUEST);

    var config = {
      method: "put",
      url: `${baseUrl}dataset/archive_data_set`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("archive dataset config", config);

    axios(config)
      .then(function (response) {
        console.log("archive dataset response", response.data);
        commit(ARCHIVE_CONTACT_DATA_SETS_SUCCESS, response.data);
        dispatch("getContactDataSets");
      })
      .catch(function (error) {
        console.log(error);
        commit(ARCHIVE_CONTACT_DATA_SETS_FAIL, error.response.data);
        dispatch("getContactDataSets");
      });
  },

  /* 
      GET DISPOSITION
    */
  async getContactDataSets({ commit }, payload) {
    commit(GET_CONTACT_DATA_SETS_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}dataset/get_datasets`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("create campaign config", config);

    axios(config)
      .then(function (response) {
        console.log("create campaign response", response.data);
        commit(GET_CONTACT_DATA_SETS_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(GET_CONTACT_DATA_SETS_FAIL, error.response.data);
        }
      });
  },

  /* 
      GET DATA SET CONTACT hEADERS
    */
  async getContactDataSetsHeader({ commit }, payload) {
    console.log("I am reaching here");
    commit(GET_CONTACT_DATA_SETS_HEADERS_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}dataset/get_dataset_headers?dataset_id=${payload.id}`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("create campaign config", config);

    axios(config)
      .then(function (response) {
        console.log("create campaign response", response.data);
        commit(GET_CONTACT_DATA_SETS_HEADERS_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(GET_CONTACT_DATA_SETS_HEADERS_FAIL, error.response.data);
        }
      });
  },

  /* 
      GET DATA SET CONTACT hEADERS
    */
  async getContactDataSetsContacts({ commit }, payload) {
    console.log("I am reaching here");
    commit(GET_CONTACT_DATA_SETS_CONTACTS_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}dataset/get_dataset_contacts?id=${payload.id}&phone_number=${payload.phone_number}&agnt_id=${payload.agent_id}&name=${payload.name}&page=${payload.page}`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("create campaign config", config);

    axios(config)
      .then(function (response) {
        console.log("create campaign response", response.data);
        commit(GET_CONTACT_DATA_SETS_CONTACTS_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(GET_CONTACT_DATA_SETS_CONTACTS_FAIL, error.response.data);
        }
      });
  },

  /* 
      GET CAMPAIGN CONTACTS DATA
    */
  async getCampaignContactsData({ commit }, payload) {
    console.log("I am reaching here");
    commit(GET_CAMPAIGN_CONTACTS_DATA_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}campaign/get_campaign_contact?campaign_id=${payload.id}&page=${payload.page}`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("create campaign config", config);

    axios(config)
      .then(function (response) {
        console.log("create campaign response", response.data);
        commit(GET_CAMPAIGN_CONTACTS_DATA_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(GET_CAMPAIGN_CONTACTS_DATA_FAIL, error.response.data);
        }
      });
  },

  /* 
      CREATE DISPOSITION
    */
  async updateContactDataSets({ commit, dispatch }, payload) {
    commit(UPDATE_CONTACT_DATA_SETS_REQUEST);

    var config = {
      method: "put",
      url: `${baseUrl}dataset/update_dataset`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("create campaign config", config);

    axios(config)
      .then(function (response) {
        console.log("create campaign response", response.data);
        commit(UPDATE_CONTACT_DATA_SETS_SUCCESS, response.data);
        dispatch("getContactDataSets");
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(UPDATE_CONTACT_DATA_SETS_FAIL, error.response.data);
          dispatch("getContactDataSets");
        }
      });
  },

  /* 
    CREATE CAMPAIGN
  */
  async createCampaign({ commit, dispatch }, payload) {
    commit(CREATE_CAMPAIGN_REQUEST);

    var config = {
      method: "post",
      url: `${baseUrl}campaign/create_campaign_once`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("create campaign config", config);

    axios(config)
      .then(function (response) {
        console.log("create campaign response", response.data);
        commit(CREATE_CAMPAIGN_SUCCESS, response.data);
        dispatch("getCampaign");
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(CREATE_CAMPAIGN_FAIL, error.response.data);
          dispatch("getCampaign");
        }
      });
  },

  /* 
      GET CAMPAIGN REPORT TABLE
    */
  async getCampaignReportTable({ commit, dispatch }, payload) {
    commit(GET_CAMPAIGN_REPORT_TABLE_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}account/campaign_report?campaign_id=${payload.campaign_id}`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("create campaign config", config);

    axios(config)
      .then(function (response) {
        console.log("get campaign reports table", response.data);
        commit(GET_CAMPAIGN_REPORT_TABLE_SUCCESS, response.data);
        dispatch("getCampaign");
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(GET_CAMPAIGN_REPORT_TABLE_FAIL, error.response.data);
          dispatch("getCampaign");
        }
      });
  },

  /* 
      GET SURVEY REPORT TABLE
    */
  async getSurveyReportTable({ commit }, payload) {
    commit(GET_SURVEY_REPORT_TABLE_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}account/survey_report?campaign_id=${payload.campaign_id}`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("create campaign config", config);

    axios(config)
      .then(function (response) {
        console.log("get survey reports table", response.data.data);
        commit(GET_SURVEY_REPORT_TABLE_SUCCESS, response.data.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(GET_SURVEY_REPORT_TABLE_FAIL, error.response.data);
        }
      });
  },

  /* 
      GET SURVEY REPORT CUMULATIVE
    */
  async getSurveyReportCumulative({ commit }, payload) {
    commit(GET_SURVEY_REPORT_CUMULATIVE_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}account/cumulative_survey_report?survey_form_item_id=${payload.form_item_id}`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("create campaign config", config);

    axios(config)
      .then(function (response) {
        console.log("get survey reports table", response.data);
        commit(GET_SURVEY_REPORT_CUMULATIVE_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(GET_SURVEY_REPORT_CUMULATIVE_FAIL, error.response.data);
        }
      });
  },
  /* 
    CREATE CAMPAIGN SURVEY
  */
  async createCampaignSurvey({ commit }, payload) {
    commit(CREATE_CAMPAIGN_REQUEST);

    var config = {
      method: "post",
      url: `${baseUrl}campaign/add_question_camapign`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("create campaign config", config);

    axios(config)
      .then(function (response) {
        console.log("create campaign response", response.data);
        commit(CREATE_CAMPAIGN_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(CREATE_CAMPAIGN_SURVEY_FAIL, error.response.data);
        }
      });
  },
  /* 
       GET CAMPAIGN TYPE
    */
  async getCampaignType({ commit }, payload) {
    commit(GET_CAMPAIGN_TYPE_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}campaign/get_campaign_type`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("get campaign type config", config);

    axios(config)
      .then(function (response) {
        console.log("get campaign type response", response.data);
        commit(GET_CAMPAIGN_TYPE_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(GET_CAMPAIGN_TYPE_FAIL, error.response.data);
        }
      });
  },
  /* 
       GET CAMPAIGN
    */
  async getCampaign({ commit }, payload) {
    commit(GET_CAMPAIGN_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}campaign/get_campaigns`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("get campaign config", config);

    axios(config)
      .then(function (response) {
        console.log("get campaign response", response.data);
        commit(GET_CAMPAIGN_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(GET_CAMPAIGN_FAIL, error.response.data);
        }
      });
  },
  /* 
       GET SENDER ID
    */
  async getSenderId({ commit }, payload) {
    commit(GET_SENDER_ID_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}campaign/get_sender_id`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("get sender id config", config);

    axios(config)
      .then(function (response) {
        console.log("get sender id response", response.data);
        commit(GET_SENDER_ID_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(GET_SENDER_ID_FAIL, error.response.data);
        }
      });
  },
  /* 
       GET QUEUE FROM CAMPAIGN
    */
  async getQueueFromCampaign({ commit }, payload) {
    commit(GET_QUEUE_FROM_CAMPAIGN_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}campaign/get_queue_from_camapaign?campaign_id=${payload.campaign_id}`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("get queue from campaign config", config);

    axios(config)
      .then(function (response) {
        console.log("get queue from campaign response", response.data);
        commit(GET_QUEUE_FROM_CAMPAIGN_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(GET_QUEUE_FROM_CAMPAIGN_FAIL, error.response.data);
        }
      });
  },
  /* 
       GET CAMPAIGN CONTACT
    */
  async getCampaignContact({ commit }, payload) {
    commit(GET_CAMPAIGN_CONTACT_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}campaign/get_campaign_contact?campaign_id=${payload.campaign_id}`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("get campaign contact config", config);

    axios(config)
      .then(function (response) {
        console.log("get campaign contact response", response.data);
        commit(GET_CAMPAIGN_CONTACT_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(GET_CAMPAIGN_CONTACT_FAIL, error.response.data);
        }
      });
  },
  /* 
       GET CAMPAIGN WORKING HOUR
    */
  async getCampaignWorkingHour({ commit }, payload) {
    commit(GET_CAMPAIGN_WORKING_HOUR_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}campaign/get_campaign_working_hour?campaign_id=${payload.campaign_id}`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("get campaign working hour config", config);

    axios(config)
      .then(function (response) {
        console.log("get campaign working hour response", response.data);
        commit(GET_CAMPAIGN_WORKING_HOUR_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(GET_CAMPAIGN_WORKING_HOUR_FAIL, error.response.data);
        }
      });
  },
  /* 
       GET CAMPAIGN WORKING HOURV2
    */
  async getCampaignWorkingHourV2({ commit }, payload) {
    commit(GET_CAMPAIGN_WORKING_HOUR_V2_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}campaign/working_hours/get_campaign_working_hour`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("get campaign working hour config", config);

    axios(config)
      .then(function (response) {
        console.log("get campaign working hour response", response.data);
        commit(GET_CAMPAIGN_WORKING_HOUR_V2_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(GET_CAMPAIGN_WORKING_HOUR_V2_FAIL, error.response.data);
        }
      });
  },
  /* 
      UPDATE CAMPAIGN
    */
  async updateCampaign({ commit, dispatch }, payload) {
    commit(UPDATE_CAMPAIGN_REQUEST);

    var config = {
      method: "put",
      url: `${baseUrl}campaign/update_campaign`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("update campaign config", config);

    axios(config)
      .then(function (response) {
        console.log("update campaign response", response.data);
        commit(UPDATE_CAMPAIGN_SUCCESS, response.data);
        dispatch("getCampaign");
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(UPDATE_CAMPAIGN_FAIL, error.response.data);
          dispatch("getCampaign");
        }
      });
  },
  /* 
      CHANGE CAMPAIGN STATUS
    */
  async changeCampaignStatus({ commit, dispatch }, payload) {
    commit(CHANGE_CAMPAIGN_STATUS_REQUEST);

    var config = {
      method: "put",
      url: `${baseUrl}campaign/change_campaign_status`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("change campaign status config", config);

    axios(config)
      .then(function (response) {
        console.log("change campaign status response", response.data);
        commit(CHANGE_CAMPAIGN_STATUS_SUCCESS, response.data);
        dispatch("getCampaign");
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(CHANGE_CAMPAIGN_STATUS_FAIL, error.response.data);
          dispatch("getCampaign");
        }
      });
  },

  /* 
    ADD GROUP TO CAMPAIGN
  */
  async addGroupToCampaign({ commit, dispatch }, payload) {
    commit(ADD_GROUP_TO_CAMPAIGN_REQUEST);

    var config = {
      method: "put",
      url: `${baseUrl}campaign/add_group_to_campaign`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("add group to campaign config", config);

    axios(config)
      .then(function (response) {
        console.log("add group to campaign response", response.data);
        commit(ADD_GROUP_TO_CAMPAIGN_SUCCESS, response.data);
        dispatch("getCampaign");
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(ADD_GROUP_TO_CAMPAIGN_FAIL, error.response.data);
          dispatch("getCampaign");
        }
      });
  },
  /* 
        REMOVE GROUP FROM CAMPAIGN
      */
  async removeGroupFromCampaign({ commit, dispatch }, payload) {
    commit(REMOVE_GROUP_FROM_CAMPAIGN_REQUEST);

    var config = {
      method: "delete",
      url: `${baseUrl}campaign/remove_group_from_campaign`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("remove group from campaign config", config);

    axios(config)
      .then(function (response) {
        console.log("remove group from campaign response", response.data);
        commit(REMOVE_GROUP_FROM_CAMPAIGN_SUCCESS, response.data);
        dispatch("getCampaign");
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(REMOVE_GROUP_FROM_CAMPAIGN_FAIL, error.response.data);
          dispatch("getCampaign");
        }
      });
  },
  /*
  ARCHIVE CAMPAIGN
  */
  async archiveCampaign({ commit, dispatch }, payload) {
    commit(ARCHIVE_CAMPAIGN_REQUEST);

    var config = {
      method: "put",
      url: `${baseUrl}campaign/archive_campaign`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: { campaign_id: payload },
    };
    console.log("archive campaign config", config);

    axios(config)
      .then(function (response) {
        console.log("archive campaign response", response.data);
        commit(ARCHIVE_CAMPAIGN_SUCCESS, response.data);
        dispatch("getCampaign");
      })
      .catch(function (error) {
        console.log(error);
        commit(ARCHIVE_CAMPAIGN_FAIL, error.response.data);
        dispatch("getCampaign");
      });
  },
  /* 
       ADD CAMPAIGN WORKING HOUR
      */
  async addCampaignWorkingHour({ commit, dispatch }, payload) {
    commit(ADD_CAMPAIGN_WORKING_HOUR_REQUEST);

    var config = {
      method: "post",
      url: `${baseUrl}campaign/add_campaign_working_hour`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("add campaign working hour config", config);

    axios(config)
      .then(function (response) {
        console.log("add campaign working hour response", response.data);
        commit(ADD_CAMPAIGN_WORKING_HOUR_SUCCESS, response.data);
        dispatch("getCampaignWorkingHour", {
          campaign_id: payload.campaign_id,
        });
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(ADD_CAMPAIGN_WORKING_HOUR_FAIL, error.response.data);
          dispatch("getCampaignWorkingHour", {
            campaign_id: payload.campaign_id,
          });
        }
      });
  },
  /* 
       CREATE CAMPAIGN WORKING HOUR V2
      */
  async createCampaignWorkingHour({ commit, dispatch }, payload) {
    commit(CREATE_CAMPAIGN_WORKING_HOUR_REQUEST);

    var config = {
      method: "post",
      url: `${baseUrl}campaign/working_hours/create_campaign_working_hour`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("add campaign working hour config", config);

    axios(config)
      .then(function (response) {
        console.log("add campaign working hour response", response.data);
        commit(CREATE_CAMPAIGN_WORKING_HOUR_SUCCESS, response.data);
        dispatch("getCampaignWorkingHourV2");
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(CREATE_CAMPAIGN_WORKING_HOUR_FAIL, error.response.data[0]);
        }
      });
  },
  /* 
       CREATE CAMPAIGN WORKING HOUR V2
      */
  async updateCampaignWorkingHour({ commit, dispatch }, payload) {
    commit(UPDATE_CAMPAIGN_WORKING_HOUR_REQUEST);

    var config = {
      method: "put",
      url: `${baseUrl}campaign/working_hours/insert_campaign_working_hour`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("add campaign working hour config", config);

    axios(config)
      .then(function (response) {
        console.log("add campaign working hour response", response.data);
        commit(UPDATE_CAMPAIGN_WORKING_HOUR_SUCCESS, response.data);
        dispatch("getCampaignWorkingHourV2");
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(UPDATE_CAMPAIGN_WORKING_HOUR_FAIL, error.response.data[0]);
        }
      });
  },
  /* 
       CREATE CAMPAIGN WORKING HOUR NAME V2
      */
  async updateCampaignWorkingHourName({ commit, dispatch }, payload) {
    commit(UPDATE_CAMPAIGN_WORKING_HOUR_NAME_REQUEST);

    var config = {
      method: "put",
      url: `${baseUrl}campaign/working_hours/update_campaign_working_hour`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("add campaign working hour config", config);

    axios(config)
      .then(function (response) {
        console.log("add campaign working hour response", response.data);
        commit(UPDATE_CAMPAIGN_WORKING_HOUR_NAME_SUCCESS, response.data);
        dispatch("getCampaignWorkingHourV2");
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(
            UPDATE_CAMPAIGN_WORKING_HOUR_NAME_FAIL,
            error.response.data[0]
          );
        }
      });
  },
  /* 
        REMOVE CAMPAIGN WORKING HOUR
      */
  async removeCampaignWorkingHour({ commit, dispatch }, payload) {
    commit(REMOVE_CAMPAIGN_WORKING_HOUR_REQUEST);

    var config = {
      method: "delete",
      url: `${baseUrl}campaign/remove_campaign_working_hour`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("remove campaign working hour config", config);

    axios(config)
      .then(function (response) {
        console.log("remove campaign working hour response", response.data);
        commit(REMOVE_CAMPAIGN_WORKING_HOUR_SUCCESS, response.data);
        dispatch("getCampaignWorkingHour", {
          campaign_id: payload.campaign_id,
        });
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(REMOVE_CAMPAIGN_WORKING_HOUR_FAIL, error.response.data);
          dispatch("getCampaignWorkingHour", {
            campaign_id: payload.campaign_id,
          });
        }
      });
  },

  /* 
    CAMPAIGN SETTING SETUP
  */
  async campaignSettingSetup({ commit, dispatch }, payload) {
    commit(CAMPAIGN_SETTING_SETUP_REQUEST);

    var config = {
      method: "put",
      url: `${baseUrl}campaign/campaign_setting_setup`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("campaign setting setup config", config);

    axios(config)
      .then(function (response) {
        console.log("campaign setting setup response", response.data);
        commit(CAMPAIGN_SETTING_SETUP_SUCCESS, response.data);
        dispatch("getCampaign");
        // dispatch("getCampaignWorkingHour");
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(CAMPAIGN_SETTING_SETUP_FAIL, error.response.data);
          dispatch("getCampaign");
          // dispatch("getCampaignWorkingHour");
        }
      });
  },
  /* 
          UPLOAD CONTACT CAMPAIGN
        */
  async uploadContactCampaign({ commit }, payload) {
    commit(UPLOAD_CONTACT_CAMPAIGN_REQUEST);

    var config = {
      method: "post",
      url: `${baseUrl}dataset/upload_contacts`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("upload contact campaign config", config);

    axios(config)
      .then(function (response) {
        console.log("upload contact campaign response", response.data);
        commit(UPLOAD_CONTACT_CAMPAIGN_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(UPLOAD_CONTACT_CAMPAIGN_FAIL, error.response.data);
        }
      });
  },
  /* 
          SELECT CONTACT FOR CAMPAIGN
        */
  async selectContactForCampaign({ commit, dispatch }, payload) {
    commit(SELECT_CONTACT_FOR_CAMPAIGN_REQUEST);

    var config = {
      method: "post",
      url: `${baseUrl}campaign/select_contact_for_campaign`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("select contact for campaign config", config);

    axios(config)
      .then(function (response) {
        console.log("select contact for campaign response", response.data);
        commit(SELECT_CONTACT_FOR_CAMPAIGN_SUCCESS, response.data);
        dispatch("getCampaign");
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(SELECT_CONTACT_FOR_CAMPAIGN_FAIL, error.response.data);
          dispatch("getCampaign");
        }
      });
  },
  /* 
          MIGRATE CAMPAIGN CONTACT BY FILTER
        */
  async migrateCampaignContactByFilter({ commit, dispatch }, payload) {
    commit(MIGRATE_CAMPAIGN_CONTACT_BY_FILTER_REQUEST);

    var config = {
      method: "post",
      url: `${baseUrl}account/migrate_campaign_contacts_by_filter`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("migrate campaign contact by filter config", config);

    axios(config)
      .then(function (response) {
        console.log(
          "migrate campaign contact by filter response",
          response.data
        );
        commit(MIGRATE_CAMPAIGN_CONTACT_BY_FILTER_SUCCESS, response.data);
        dispatch("getCampaign");
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(MIGRATE_CAMPAIGN_CONTACT_BY_FILTER_FAIL, error.response.data);
          dispatch("getCampaign");
        }
      });
  },
  /* 
          ADD QUESTION CAMPAIGN
        */
  async addQuestionCampaign({ commit, dispatch }, payload) {
    commit(ADD_QUESTION_CAMPAIGN_REQUEST);

    var config = {
      method: "post",
      url: `${baseUrl}campaign/add_question_camapign`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("add question campaign config", config);

    axios(config)
      .then(function (response) {
        console.log("add question campaign response", response.data);
        commit(ADD_QUESTION_CAMPAIGN_SUCCESS, response.data);
        dispatch("getCampaign");
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(ADD_QUESTION_CAMPAIGN_FAIL, error.response.data);
          dispatch("getCampaign");
        }
      });
  },
  //submit desposition
  async submitDisposition({ commit }, payload) {
    commit(SUBMIT_DESPOSITION_REQUEST);

    var config = {
      method: "post",
      url: `${baseUrl}campaign/submit_desposition`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("submit disposition config", config);

    axios(config)
      .then(function (response) {
        console.log("submit disposition response", response.data);
        commit(SUBMIT_DESPOSITION_SUCCESS, response.data);
        // dispatch("getCampaign");
      })
      .catch(function (error) {
        console.log(error);
        commit(SUBMIT_DESPOSITION_FAIL, error.response.data);
        // dispatch("getCampaign");
      });
  },
  ///remove non-contacted camapgin contacts
  async removeNonContactedCampaigns({ commit }, payload) {
    commit(REMOVE_NONCONTACTED_REQUEST);

    var config = {
      method: "delete",
      url: `${baseUrl}campaign/remove_non_contacted_campaigns?campaign_id=${payload.campaignId}`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    console.log("remove non contacted campaign config", config);

    axios(config)
      .then(function (response) {
        console.log("remove non contacted campaign response", response.data);
        commit(REMOVE_NONCONTACTED_SUCCESS, response.data);
        // dispatch("getCampaign");
      })
      .catch(function (error) {
        console.log(error);
        commit(REMOVE_NONCONTACTED_FAIL, error.response.data);
        // dispatch("getCampaign");
      });
  },

  /* 
      GET CAMPAIGN CONTACTS DATA
    */
  async getCampaignReportsV2({ commit }, payload) {
    console.log("I am reaching here");
    commit(GET_CAMPAIGN_REPORTS_V2_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}campaign/get_campaign_call_report?campaign_id=${payload.id}&from=${payload.from}&to=${payload.to}`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("create campaign config", config);

    axios(config)
      .then(function (response) {
        console.log("create campaign response", response.data);
        commit(GET_CAMPAIGN_REPORTS_V2_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(GET_CAMPAIGN_REPORTS_V2_FAIL, error.response.data);
        }
      });
  },

  //campaign call report
  async getcampaignCallReport({ commit }, payload) {
    commit(GET_CAMPAIGN_CALL_REPORT_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}campaign/get_campaign_call_report?campaign_id=${payload.id}from=${payload.from}&to=${payload.to}`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Access-Control-Allow-Origin": "*",
      },
      data: payload,
    };
    console.log("campaign call report config", config);

    axios(config)
      .then(function (response) {
        console.log("campaign call report response", response.data);
        commit(GET_CAMPAIGN_CALL_REPORT_SUCCESS, response.data);
        // dispatch("getCampaign");
      })
      .catch(function (error) {
        console.log(error);
        commit(GET_CAMPAIGN_CALL_REPORT_FAIL, error.response.data);
        // dispatch("getCampaign");
      });
  },
  /* 
    GET DISPOSITION
  */
  async getCampaignDisposition({ commit }, payload) {
    commit(GET_CAMPAIGN_DISPOSITIONS_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}campaign/getCampaignDespositionSelection?campaign_id=${payload.id}`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("create campaign config", config);

    axios(config)
      .then(function (response) {
        console.log("I am getting dispositions", response.data);
        commit(GET_CAMPAIGN_DISPOSITIONS_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(GET_CAMPAIGN_DISPOSITIONS_FAIL, error.response.data);
        }
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  strict: process.env.NODE_ENV != "production",
};
