import axios from "axios";
import router from "@/router";
const baseUrl = process.env.VUE_APP_API_URL;
export const CREATE_WORKFLOW_REQUEST = "CREATE_WORKFLOW_REQUEST";
export const CREATE_WORKFLOW_SUCCESS = "CREATE_WORKFLOW_SUCCESS";
export const CREATE_WORKFLOW_FAIL = "CREATE_WORKFLOW_FAIL";

export const GET_WORKFLOW_REQUEST = "GET_WORKFLOW_REQUEST";
export const GET_WORKFLOW_SUCCESS = "GET_WORKFLOW_SUCCESS";
export const GET_WORKFLOW_FAIL = "GET_WORKFLOW_FAIL";

export const EDIT_WORKFLOW_REQUEST = "EDIT_WORKFLOW_REQUEST";
export const EDIT_WORKFLOW_SUCCESS = "EDIT_WORKFLOW_SUCCESS";
export const EDIT_WORKFLOW_FAIL = "EDIT_WORKFLOW_FAIL";

//FORKFLOW TEAMS

export const ADD_USERS_TO_TEAM_REQUEST = "ADD_USERS_TO_TEAM_REQUEST";
export const ADD_USERS_TO_TEAM_SUCCESS = "ADD_USERS_TO_TEAM_SUCCESS";
export const ADD_USERS_TO_TEAM_FAIL = "ADD_USERS_TO_TEAM_FAIL";

export const GET_TEAM_USERS_REQUEST = "GET_TEAM_USERS_REQUEST";
export const GET_TEAM_USERS_SUCCESS = "GET_TEAM_USERS_SUCCESS";
export const GET_TEAM_USERS_FAIL = "GET_TEAM_USERS_FAIL";

export const DELETE_TEAM_USERS_REQUEST = "DELETE_TEAM_USERS_REQUEST";
export const DELETE_TEAM_USERS_SUCCESS = "DELETE_TEAM_USERS_SUCCESS";
export const DELETE_TEAM_USERS_FAIL = "DELETE_TEAM_USERS_FAIL";

//FORKFLOW TEAMS USERS

export const CREATE_WORKFLOW_TEAM_REQUEST = "CREATE_WORKFLOW_TEAM_REQUEST";
export const CREATE_WORKFLOW_TEAM_SUCCESS = "CREATE_WORKFLOW_TEAM_SUCCESS";
export const CREATE_WORKFLOW_TEAM_FAIL = "CREATE_WORKFLOW_TEAM_FAIL";

export const GET_WORKFLOW_TEAM_REQUEST = "GET_WORKFLOW_TEAM_REQUEST";
export const GET_WORKFLOW_TEAM_SUCCESS = "GET_WORKFLOW_TEAM_SUCCESS";
export const GET_WORKFLOW_TEAM_FAIL = "GET_WORKFLOW_TEAM_FAIL";

export const EDIT_WORKFLOW_TEAM_REQUEST = "EDIT_WORKFLOW_TEAM_REQUEST";
export const EDIT_WORKFLOW_TEAM_SUCCESS = "EDIT_WORKFLOW_TEAM_SUCCESS";
export const EDIT_WORKFLOW_TEAM_FAIL = "EDIT_WORKFLOW_TEAM_FAIL";

//FORKFLOW STEPS

export const CREATE_WORKFLOW_STEP_REQUEST = "CREATE_WORKFLOW_STEP_REQUEST";
export const CREATE_WORKFLOW_STEP_SUCCESS = "CREATE_WORKFLOW_STEP_SUCCESS";
export const CREATE_WORKFLOW_STEP_FAIL = "CREATE_WORKFLOW_STEP_FAIL";

export const GET_WORKFLOW_STEP_REQUEST = "GET_WORKFLOW_STEP_REQUEST";
export const GET_WORKFLOW_STEP_SUCCESS = "GET_WORKFLOW_STEP_SUCCESS";
export const GET_WORKFLOW_STEP_FAIL = "GET_WORKFLOW_STEP_FAIL";

export const GET_WORKFLOW_NEXT_STEP_REQUEST = "GET_WORKFLOW_NEXT_STEP_REQUEST";
export const GET_WORKFLOW_NEXT_STEP_SUCCESS = "GET_WORKFLOW_NEXT_STEP_SUCCESS";
export const GET_WORKFLOW_NEXT_STEP_FAIL = "GET_WORKFLOW_NEXT_STEP_FAIL";

export const GET_WORKFLOW_MINE_STEP_REQUEST = "GET_WORKFLOW_MINE_STEP_REQUEST";
export const GET_WORKFLOW_MINE_STEP_SUCCESS = "GET_WORKFLOW_MINE_STEP_SUCCESS";
export const GET_WORKFLOW_MINE_STEP_FAIL = "GET_WORKFLOW_MINE_STEP_FAIL";

//WORKFLOW FLOW

export const CREATE_WORKFLOW_FLOW_REQUEST = "CREATE_WORKFLOW_FLOW_REQUEST";
export const CREATE_WORKFLOW_FLOW_SUCCESS = "CREATE_WORKFLOW_FLOW_SUCCESS";
export const CREATE_WORKFLOW_FLOW_FAIL = "CREATE_WORKFLOW_FLOW_FAIL";

export const GET_WORKFLOW_FLOW_REQUEST = "GET_WORKFLOW_FLOW_REQUEST";
export const GET_WORKFLOW_FLOW_SUCCESS = "GET_WORKFLOW_FLOW_SUCCESS";
export const GET_WORKFLOW_FLOW_FAIL = "GET_WORKFLOW_FLOW_FAIL";

export const GET_WORKFLOW_OPEN_REQUEST = "GET_WORKFLOW_OPEN_REQUEST";
export const GET_WORKFLOW_OPEN_SUCCESS = "GET_WORKFLOW_OPEN_SUCCESS";
export const GET_WORKFLOW_OPEN_FAIL = "GET_WORKFLOW_OPEN_FAIL";

export const GET_WORKFLOW_OWNED_REQUEST = "GET_WORKFLOW_OWNED_REQUEST";
export const GET_WORKFLOW_OWNED_SUCCESS = "GET_WORKFLOW_OWNED_SUCCESS";
export const GET_WORKFLOW_OWNED_FAIL = "GET_WORKFLOW_OWNED_FAIL";

export const GET_WORKFLOW_MINE_REQUEST = "GET_WORKFLOW_MINE_REQUEST";
export const GET_WORKFLOW_MINE_SUCCESS = "GET_WORKFLOW_MINE_SUCCESS";
export const GET_WORKFLOW_MINE_FAIL = "GET_WORKFLOW_MINE_FAIL";

//GET WORKFLOW FIRST STEP

export const GET_WORKFLOW_FIRST_STEP_REQUEST =
  "GET_WORKFLOW_FIRST_STEP_REQUEST";
export const GET_WORKFLOW_FIRST_STEP_SUCCESS =
  "GET_WORKFLOW_FIRST_STEP_SUCCESS";
export const GET_WORKFLOW_FIRST_STEP_FAIL = "GET_WORKFLOW_FIRST_STEP_FAIL";

//CREATE WORKFLOW ENTRY

export const CREATE_WORKFLOW_FIRST_ENTRY_REQUEST =
  "CREATE_WORKFLOW_FIRST_ENTRY_REQUEST";
export const CREATE_WORKFLOW_FIRST_ENTRY_SUCCESS =
  "CREATE_WORKFLOW_FIRST_ENTRY_SUCCESS";
export const CREATE_WORKFLOW_FIRST_ENTRY_FAIL =
  "CREATE_WORKFLOW_FIRST_ENTRY_FAIL";

const state = {
  workFlows: [],
  workFlowForm: [],
  selectWorkFlows: [],
  loadingWorkFlows: false,
  successWorkflows: "",
  errorWorkFlows: "",
  workFlowTeams: [],
  workFlowTeamsSelect: [],
  teamUsers: [],
  workflowSteps: [],
  workFlowFlow: [],
  workFlowOpen: [],
  workFlowOwned: [],
  workFlowMine: [],
  workFlowMineStep: [],
  workFlowNextStep: [],
};

const getters = {
  workFlows: (state) => state.workFlows,
  workFlowForm: (state) => state.workFlowForm,
  selectWorkFlows: (state) => state.selectWorkFlows,
  workFlowTeams: (state) => state.workFlowTeams,
  loadingWorkFlows: (state) => state.loadingWorkFlows,
  successWorkflows: (state) => state.successWorkflows,
  errorWorkFlows: (state) => state.errorWorkFlows,
  teamUsers: (state) => state.teamUsers,
  workflowSteps: (state) => state.workflowSteps,
  workFlowTeamsSelect: (state) => state.workFlowTeamsSelect,
  workFlowFlow: (state) => state.workFlowFlow,
  workFlowOpen: (state) => state.workFlowOpen,
  workFlowOwned: (state) => state.workFlowOwned,
  workFlowMine: (state) => state.workFlowMine,
  workFlowMineStep: (state) => state.workFlowMineStep,
  workFlowNextStep: (state) => state.workFlowNextStep,
};

const mutations = {
  //CREATE WORKFLOWS
  [CREATE_WORKFLOW_REQUEST](state) {
    state.loadingWorkFlows = true;
    state.errorWorkFlows = "";
  },

  [CREATE_WORKFLOW_SUCCESS](state, payload) {
    state.loadingWorkFlows = false;
    state.successWorkflows = payload;
  },
  [CREATE_WORKFLOW_FAIL](state, payload) {
    state.loadingWorkFlows = false;
    state.errorWorkFlows = payload;
  },
  //Get All Work FLows

  [GET_WORKFLOW_REQUEST](state) {
    state.loadingWorkFlows = true;
    state.errorWorkFlows = "";
  },

  [GET_WORKFLOW_SUCCESS](state, payload) {
    state.loadingWorkFlows = false;
    state.successWorkflows = "Workflows Successfully Fetched";
    state.workFlows = payload;
    state.selectWorkFlows = payload.map((user) => {
      return { label: user.name, value: user.id };
    });
    console.log("these are the workflow forms", payload);
  },
  [GET_WORKFLOW_FAIL](state, payload) {
    state.loadingWorkFlows = false;
    state.errorWorkFlows = payload;
  },
  //Edit A Work FLows

  [EDIT_WORKFLOW_REQUEST](state) {
    state.loadingWorkFlows = true;
    state.errorWorkFlows = "";
  },

  [EDIT_WORKFLOW_SUCCESS](state, payload) {
    state.loadingWorkFlows = false;
    state.successWorkflows = payload;
  },
  [EDIT_WORKFLOW_FAIL](state, payload) {
    state.loadingWorkFlows = false;
    state.errorWorkFlows = payload;
  },

  //CREATE WORKFLOWS TEAMS
  [CREATE_WORKFLOW_TEAM_REQUEST](state) {
    state.loadingWorkFlows = true;
    state.errorWorkFlows = "";
  },

  [CREATE_WORKFLOW_TEAM_SUCCESS](state, payload) {
    state.loadingWorkFlows = false;
    state.successWorkflows = payload;
  },
  [CREATE_WORKFLOW_TEAM_FAIL](state, payload) {
    state.loadingWorkFlows = false;
    state.errorWorkFlows = payload;
  },
  //Get All Work FLows TEAMS

  [GET_WORKFLOW_TEAM_REQUEST](state) {
    state.loadingWorkFlows = true;
    state.errorWorkFlows = "";
  },

  [GET_WORKFLOW_TEAM_SUCCESS](state, payload) {
    state.loadingWorkFlows = false;
    state.successWorkflows = "Workflow Teams Successfully Fetched";
    state.workFlowTeams = payload;
    state.workFlowTeamsSelect = payload.map((form) => {
      return { label: form.name, value: form.id };
    });
    console.log("these are the workflow Teams", payload);
  },
  [GET_WORKFLOW_TEAM_FAIL](state, payload) {
    state.loadingWorkFlows = false;
    state.errorWorkFlows = payload;
  },
  //Edit A Work FLows TEAMS

  [EDIT_WORKFLOW_TEAM_REQUEST](state) {
    state.loadingWorkFlows = true;
    state.errorWorkFlows = "";
  },

  [EDIT_WORKFLOW_TEAM_SUCCESS](state, payload) {
    state.loadingWorkFlows = false;
    state.successWorkflows = payload;
  },
  [EDIT_WORKFLOW_TEAM_FAIL](state, payload) {
    state.loadingWorkFlows = false;
    state.errorWorkFlows = payload;
  },

  //Ad Users to team
  [ADD_USERS_TO_TEAM_REQUEST](state) {
    state.loadingWorkFlows = true;
    state.errorWorkFlows = "";
  },

  [ADD_USERS_TO_TEAM_SUCCESS](state, payload) {
    state.loadingWorkFlows = false;
    state.successWorkflows = payload;
  },
  [ADD_USERS_TO_TEAM_FAIL](state, payload) {
    state.loadingWorkFlows = false;
    state.errorWorkFlows = payload;
  },
  //Get Team Users

  [GET_TEAM_USERS_REQUEST](state) {
    state.loadingWorkFlows = true;
    state.errorWorkFlows = "";
  },

  [GET_TEAM_USERS_SUCCESS](state, payload) {
    state.loadingWorkFlows = false;
    state.successWorkflows = "Workflow Teams Successfully Fetched";
    state.teamUsers = payload;
    console.log("these are the workflow Team Users", payload);
  },
  [GET_TEAM_USERS_FAIL](state, payload) {
    state.loadingWorkFlows = false;
    state.errorWorkFlows = payload;
  },
  //Delete team users

  [DELETE_TEAM_USERS_REQUEST](state) {
    state.loadingWorkFlows = true;
    state.errorWorkFlows = "";
  },

  [DELETE_TEAM_USERS_SUCCESS](state, payload) {
    state.loadingWorkFlows = false;
    state.successWorkflows = payload;
  },
  [DELETE_TEAM_USERS_FAIL](state, payload) {
    state.loadingWorkFlows = false;
    state.errorWorkFlows = payload;
  },

  //CREATE WORKFLOWS Steps
  [CREATE_WORKFLOW_STEP_REQUEST](state) {
    state.loadingWorkFlows = true;
    state.errorWorkFlows = "";
  },

  [CREATE_WORKFLOW_STEP_SUCCESS](state, payload) {
    state.loadingWorkFlows = false;
    state.successWorkflows = payload;
  },
  [CREATE_WORKFLOW_STEP_FAIL](state, payload) {
    state.loadingWorkFlows = false;
    state.errorWorkFlows = payload;
  },
  //Get All Work FLows

  [GET_WORKFLOW_STEP_REQUEST](state) {
    state.loadingWorkFlows = true;
    state.errorWorkFlows = "";
  },

  [GET_WORKFLOW_STEP_SUCCESS](state, payload) {
    state.loadingWorkFlows = false;
    state.successWorkflows = "Workflows Steps Successfully Fetched";
    state.workflowSteps = payload;
    console.log("these are the workflow  Steps", payload);
  },
  [GET_WORKFLOW_STEP_FAIL](state, payload) {
    state.loadingWorkFlows = false;
    state.errorWorkFlows = payload;
  },

  //Get All Work FLows

  [GET_WORKFLOW_NEXT_STEP_REQUEST](state) {
    state.loadingWorkFlows = true;
    state.errorWorkFlows = "";
  },

  [GET_WORKFLOW_NEXT_STEP_SUCCESS](state, payload) {
    state.loadingWorkFlows = false;
    state.successWorkflows = "Workflows Steps Successfully Fetched";
    state.workFlowNextStep = payload;
    console.log("these are the workflow  Steps", payload);
  },
  [GET_WORKFLOW_NEXT_STEP_FAIL](state, payload) {
    state.loadingWorkFlows = false;
    state.errorWorkFlows = payload;
  },
  //CREATE WORKFLOWS FLOW
  [CREATE_WORKFLOW_FLOW_REQUEST](state) {
    state.loadingWorkFlows = true;
    state.errorWorkFlows = "";
  },

  [CREATE_WORKFLOW_FLOW_SUCCESS](state, payload) {
    state.loadingWorkFlows = false;
    state.successWorkflows = payload;
  },
  [CREATE_WORKFLOW_FLOW_FAIL](state, payload) {
    state.loadingWorkFlows = false;
    state.errorWorkFlows = payload;
  },

  //Get  Work FLows Open

  [GET_WORKFLOW_FLOW_REQUEST](state) {
    state.loadingWorkFlows = true;
    state.errorWorkFlows = "";
  },

  [GET_WORKFLOW_FLOW_SUCCESS](state, payload) {
    state.loadingWorkFlows = false;
    state.successWorkflows = "Workflow Flow Successfully Fetched";
    state.workFlowFlow = payload;
    console.log("these are the workflow forms", payload);
  },
  [GET_WORKFLOW_FLOW_FAIL](state, payload) {
    state.loadingWorkFlows = false;
    state.errorWorkFlows = payload;
  },

  //Get  Work FLows fLOW

  [GET_WORKFLOW_OPEN_REQUEST](state) {
    state.loadingWorkFlows = true;
    state.errorWorkFlows = "";
  },

  [GET_WORKFLOW_OPEN_SUCCESS](state, payload) {
    state.loadingWorkFlows = false;
    state.successWorkflows = "Workflow Flow Successfully Fetched";
    state.workFlowOpen = payload;
    console.log("these are the workflow forms", payload);
  },
  [GET_WORKFLOW_OPEN_FAIL](state, payload) {
    state.loadingWorkFlows = false;
    state.errorWorkFlows = payload;
  },

  //Get  Work FLows fLOW

  [GET_WORKFLOW_OWNED_REQUEST](state) {
    state.loadingWorkFlows = true;
    state.errorWorkFlows = "";
  },

  [GET_WORKFLOW_OWNED_SUCCESS](state, payload) {
    state.loadingWorkFlows = false;
    state.successWorkflows = "Workflow Flow Successfully Fetched";
    state.workFlowOwned = payload;
    console.log("these are the workflow forms", payload);
  },
  [GET_WORKFLOW_OWNED_FAIL](state, payload) {
    state.loadingWorkFlows = false;
    state.errorWorkFlows = payload;
  },

  //Get  Work FLows Mine

  [GET_WORKFLOW_MINE_REQUEST](state) {
    state.loadingWorkFlows = true;
    state.errorWorkFlows = "";
  },

  [GET_WORKFLOW_MINE_SUCCESS](state, payload) {
    state.loadingWorkFlows = false;
    state.successWorkflows = "Workflow Flow Successfully Fetched";
    state.workFlowMine = payload;
    console.log("these are the workflow forms", payload);
  },
  [GET_WORKFLOW_MINE_FAIL](state, payload) {
    state.loadingWorkFlows = false;
    state.errorWorkFlows = payload;
  },

  //Get  Work FLows Mine

  [GET_WORKFLOW_MINE_STEP_REQUEST](state) {
    state.loadingWorkFlows = true;
    state.errorWorkFlows = "";
  },

  [GET_WORKFLOW_MINE_STEP_SUCCESS](state, payload) {
    state.loadingWorkFlows = false;
    state.successWorkflows = "Workflow Flow Successfully Fetched";
    state.workFlowMineStep = payload;
    console.log("these are the workflow forms", payload);
  },
  [GET_WORKFLOW_MINE_STEP_FAIL](state, payload) {
    state.loadingWorkFlows = false;
    state.errorWorkFlows = payload;
  },

  //Get  Work FLows Form

  [GET_WORKFLOW_FIRST_STEP_REQUEST](state) {
    state.loadingWorkFlows = true;
    state.errorWorkFlows = "";
  },

  [GET_WORKFLOW_FIRST_STEP_SUCCESS](state, payload) {
    state.loadingWorkFlows = false;
    state.successWorkflows = "Workflow Flow Successfully Fetched";
    state.workFlowForm = payload;
    console.log("these are the workflow forms", payload);
  },
  [GET_WORKFLOW_FIRST_STEP_FAIL](state, payload) {
    state.loadingWorkFlows = false;
    state.errorWorkFlows = payload;
  },
  ///CREATE WORK FLOW ENTRY

  //CREATE WORKFLOWS
  [CREATE_WORKFLOW_FIRST_ENTRY_REQUEST](state) {
    state.loadingWorkFlows = true;
    state.errorWorkFlows = "";
  },

  [CREATE_WORKFLOW_FIRST_ENTRY_SUCCESS](state, payload) {
    state.loadingWorkFlows = false;
    state.successWorkflows = payload;
    console.log("these is the workflow entry", payload);
  },
  [CREATE_WORKFLOW_FIRST_ENTRY_FAIL](state, payload) {
    state.loadingWorkFlows = false;
    state.errorWorkFlows = payload;
    console.log("this is the error i am getting", payload);
  },
};
const actions = {
  // create workflow flow

  async createWorkflowFlow({ commit, dispatch }, payload) {
    commit(CREATE_WORKFLOW_FLOW_REQUEST);

    var config = {
      method: "post",
      url: `${baseUrl}workflow/create_step`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };

    axios(config)
      .then(function (response) {
        commit(CREATE_WORKFLOW_FLOW_SUCCESS, response.data);
        dispatch("getWorkFlows");
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(CREATE_WORKFLOW_FLOW_FAIL, error.response.data);
        }
      });
  },

  //get workflow flow

  async getWorkFlowFlow({ commit }, payload) {
    commit(GET_WORKFLOW_FLOW_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}workflow/get_workflow_step?work_flow_id=${payload.id}`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };

    axios(config)
      .then(function (response) {
        commit(GET_WORKFLOW_FLOW_SUCCESS, response.data);
        router.push({ name: "WorkFlowDrawFlow" });
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(GET_WORKFLOW_FLOW_FAIL, error.response.data);
        }
      });
  },

  //get workflow flow

  async getWorkFlowOpen({ commit }, payload) {
    commit(GET_WORKFLOW_OPEN_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}workflow/get_my_team_workflow_entries`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };

    axios(config)
      .then(function (response) {
        commit(GET_WORKFLOW_OPEN_SUCCESS, response.data.data);
        console.log("these are the Open Workflows", response.data.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(GET_WORKFLOW_OPEN_FAIL, error.response.data);
        }
      });
  },

  //get workflow flow

  async getWorkFlowOwned({ commit }, payload) {
    commit(GET_WORKFLOW_OWNED_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}workflow/get_owened_workflow_entries`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };

    axios(config)
      .then(function (response) {
        commit(GET_WORKFLOW_OWNED_SUCCESS, response.data.data);
        console.log("these are the Open Workflows", state.workFlowOwned);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(GET_WORKFLOW_OWNED_FAIL, error.response.data);
        }
      });
  },

  //get workflow Mine

  async getWorkFlowMine({ commit }, payload) {
    commit(GET_WORKFLOW_MINE_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}workflow/getMyWorkflowEntries`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };

    axios(config)
      .then(function (response) {
        commit(GET_WORKFLOW_MINE_SUCCESS, response.data.data);
        console.log("these are the Open Workflows", state.workFlowOwned);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(GET_WORKFLOW_MINE_FAIL, error.response.data);
        }
      });
  },

  //get workflow Mine

  async getWorkFlowMineStep({ commit }, payload) {
    commit(GET_WORKFLOW_MINE_STEP_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}workflow/getWorkflowEntryDetail?workflow_entry_id=${payload.id}`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };

    axios(config)
      .then(function (response) {
        commit(GET_WORKFLOW_MINE_STEP_SUCCESS, response.data);
        console.log("these are the Open Workflows", state.workFlowMineStep);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(GET_WORKFLOW_MINE_STEP_FAIL, error.response.data);
        }
      });
  },

  async createWorkflow({ commit, dispatch }, payload) {
    commit(CREATE_WORKFLOW_REQUEST);

    var config = {
      method: "post",
      url: `${baseUrl}workflow/create_workflow`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };

    axios(config)
      .then(function (response) {
        commit(CREATE_WORKFLOW_SUCCESS, response.data);
        dispatch("getWorkFlows");
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(CREATE_WORKFLOW_FAIL, error.response.data);
        }
      });
  },

  async getWorkFlows({ commit }, payload) {
    commit(GET_WORKFLOW_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}workflow/get_workflows`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };

    axios(config)
      .then(function (response) {
        commit(GET_WORKFLOW_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(GET_WORKFLOW_FAIL, error.response.data);
        }
      });
  },

  async editWorkFlow({ commit, dispatch }, payload) {
    commit(EDIT_WORKFLOW_REQUEST);

    var config = {
      method: "put",
      url: `${baseUrl}workflow/update_workflow`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };

    axios(config)
      .then(function (response) {
        commit(EDIT_WORKFLOW_SUCCESS, response.data);
        dispatch("getWorkFlows");
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(EDIT_WORKFLOW_FAIL, error.response.data);
        }
      });
  },

  // Work Flow Teams Actions
  async createWorkflowTeams({ commit, dispatch }, payload) {
    commit(CREATE_WORKFLOW_TEAM_REQUEST);

    var config = {
      method: "post",
      url: `${baseUrl}workflow/create_workflow_team`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };

    axios(config)
      .then(function (response) {
        commit(CREATE_WORKFLOW_TEAM_SUCCESS, response.data);
        dispatch("getWorkFlowsTeams");
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(CREATE_WORKFLOW_TEAM_FAIL, error.response.data);
        }
      });
  },

  async getWorkFlowsTeams({ commit }, payload) {
    commit(GET_WORKFLOW_TEAM_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}workflow/get_workflow_team`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };

    axios(config)
      .then(function (response) {
        commit(GET_WORKFLOW_TEAM_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(GET_WORKFLOW_TEAM_FAIL, error.response.data);
        }
      });
  },

  async editWorkFlowTeams({ commit, dispatch }, payload) {
    commit(EDIT_WORKFLOW_TEAM_REQUEST);

    var config = {
      method: "put",
      url: `${baseUrl}workflow/update_workflow_team`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };

    axios(config)
      .then(function (response) {
        commit(EDIT_WORKFLOW_TEAM_SUCCESS, response.data);
        dispatch("getWorkFlowsTeams");
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(EDIT_WORKFLOW_TEAM_FAIL, error.response.data);
        }
      });
  },

  //Manage Team Users
  async addTeamUsers({ commit }, payload) {
    commit(ADD_USERS_TO_TEAM_REQUEST);

    var config = {
      method: "post",
      url: `${baseUrl}workflow/add_user_workflow_team`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };

    axios(config)
      .then(function (response) {
        commit(ADD_USERS_TO_TEAM_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(ADD_USERS_TO_TEAM_FAIL, error.response.data);
        }
      });
  },

  async getTeamUsers({ commit }, payload) {
    commit(GET_TEAM_USERS_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}workflow/get_user_workflow_team?workflow_team_id=${payload.id}`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };

    axios(config)
      .then(function (response) {
        commit(GET_TEAM_USERS_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(GET_TEAM_USERS_FAIL, error.response.data);
        }
      });
  },

  async deleteTeamUsers({ commit }, payload) {
    commit(DELETE_TEAM_USERS_REQUEST);

    var config = {
      method: "delete",
      url: `${baseUrl}workflow/remove_user_workflow_team`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };

    axios(config)
      .then(function (response) {
        commit(DELETE_TEAM_USERS_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(DELETE_TEAM_USERS_FAIL, error.response.data);
        }
      });
  },

  //Workflow Steps

  async createWorkflowSteps({ commit, dispatch }, payload) {
    commit(CREATE_WORKFLOW_STEP_REQUEST);

    var config = {
      method: "post",
      url: `${baseUrl}workflow/create_step`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };

    axios(config)
      .then(function (response) {
        commit(CREATE_WORKFLOW_STEP_SUCCESS, response.data);
        dispatch("getWorkFlows");
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(CREATE_WORKFLOW_STEP_FAIL, error.response.data);
        }
      });
  },

  async getWorkFlowSteps({ commit }, payload) {
    commit(GET_WORKFLOW_STEP_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}workflow/get_workflow_step?work_flow_id=${payload.id}`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };

    axios(config)
      .then(function (response) {
        commit(GET_WORKFLOW_STEP_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(GET_WORKFLOW_STEP_FAIL, error.response.data);
        }
      });
  },

  async getWorkFlowNextStep({ commit }, payload) {
    commit(GET_WORKFLOW_NEXT_STEP_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}workflow/get_workflow_next_step?workflow_entry_id=${payload.id}`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };

    axios(config)
      .then(function (response) {
        commit(GET_WORKFLOW_NEXT_STEP_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(GET_WORKFLOW_NEXT_STEP_FAIL, error.response.data);
        }
      });
  },
  async getWorkFlowFirstStep({ commit }, payload) {
    commit(GET_WORKFLOW_FIRST_STEP_REQUEST);

    var config = {
      method: "get",
      url: `${baseUrl}workflow/get_workflow_fist_step?work_flow_id=${payload.workflow_id}`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };

    axios(config)
      .then(function (response) {
        commit(GET_WORKFLOW_FIRST_STEP_SUCCESS, response.data);
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(GET_WORKFLOW_FIRST_STEP_FAIL, error.response.data);
        }
      });
  },
  //create work flow first entry
  async createWorkflowfirstEntry({ commit }, payload) {
    commit(CREATE_WORKFLOW_FIRST_ENTRY_REQUEST);

    var config = {
      method: "post",
      url: `${baseUrl}workflow/create_work_flow_entry`,
      headers: {
        "Content-Type": "application/json",
        "x-requested-with": "XMLHttpRequest",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      data: payload,
    };
    console.log("this is the config for creating an entry", config);

    axios(config)
      .then(function (response) {
        commit(CREATE_WORKFLOW_FIRST_ENTRY_SUCCESS, response.data);
        // dispatch("getWorkFlows");
      })
      .catch(function (error) {
        console.log(error);
        if (
          error.response.status == 401 ||
          error.response.data.message == "Unauthenticated."
        ) {
          console.log("logged in from another device");
          commit("auth/LOGOUT_SUCCESS", null, { root: true });
          router.replace({ name: "LogIn" });
        } else {
          commit(CREATE_WORKFLOW_FIRST_ENTRY_FAIL, error);
        }
      });
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
  strict: process.env.NODE_ENV != "production",
};
